import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../Axios';

function TeamEventDetails() {
  const { id } = useParams();
  const [tournament, setTournament] = useState({});
  const [teams, setTeams] = useState([]);
  const [data, setData] = useState(false);
  const [teamCount, setTeamCount] = useState(0);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(`/tournament/${id}`)
      .then((response) => {
        setTournament(response.data);
        const cate = [];
        for (let i = 0; i < response.data.CATEGORY.length; i++) {
          const n = response.data.AGE_CATEGORY[i] + " " + response.data.CATEGORY[i];
          cate.push(n);
        }
        let cnt = 0;
        for (let i = 0; i < response.data.SPOT_STATUS_ARRAY.length; i++) {
          if (response.data.SPOT_STATUS_ARRAY[i] !== "")
            cnt++;
        }
        setTeamCount(cnt);
        setCategory(cate);
        setLoading(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(true);
      });
  }, [id]);

  useEffect(() => {
    axios.get(`/getTeamsByEventID/${id}`)
      .then((response) => {
        setTeams(response.data);
        if (response.data.length > 0)
          setData(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  return (
    <>
      <div>
        <h1 className="head" style={{ color: "black" }}>{tournament.EVENT_NAME}</h1>
        {loading ? (
          <>
            <div className="summary-container">
              <div className="summary-details">
                <div className="summary-field">
                  <p className="label">Total Teams:</p>
                  <p className="value">{teamCount}</p>
                </div>
                <div className="summary-field">
                  <p className="label">Team Size:</p>
                  <p className="value">{tournament.TEAM_SIZE}</p>
                </div>
                <div className="summary-field">
                  <p className="label">Entry Fee:</p>
                  <p className="value">Rs. {tournament.ENTRY_FEE}</p>
                </div>
                <div className="summary-field">
                  <p className="label">GOLD:</p>
                  <p className="value">Rs. {tournament.GOLD}</p>
                </div>
                <div className="summary-field">
                  <p className="label">SILVER:</p>
                  <p className="value">Rs. {tournament.SILVER}</p>
                </div>
                <div className="summary-field">
                  <p className="label">BRONZE:</p>
                  <p className="value">Rs. {tournament.BRONZE}</p>
                </div>
                <div className="summary-field">
                  <p className="label">PRIZE POOL:</p>
                  <p className="value">Rs. {tournament.PRIZE_POOL}</p>
                </div>
                <div className="summary-field">
                  <p className="label"> COURTS:</p>
                  <p className="value">{tournament.NO_OF_COURTS}</p>
                </div>
                <div className="summary-field">
                  <p className="label">CATEGORIES:</p>
                  <ul>
                    {category.map((category, index) => (
                      <li key={index}>{category}</li>
                    ))}
                  </ul>
                </div>

              </div>
            </div>
          </>
        ) : (
          <h3 style={{ color: "black", textAlign: "center", marginTop: "10%" }}>Loading..</h3>
        )}
        <div>
          {data ? (
            <table className="summary-table">
              <thead>
                <tr>
                  <th>Team ID</th>
                  <th>Team Name</th>
                  {[...Array(tournament.TEAM_SIZE)].map((_, index) => (
                    <th key={index}>PLAYER {index + 1}</th>
                  ))}
                  <th>Booking Through</th>
                </tr>
              </thead>
              <tbody>
                {teams.map((team) => (
                  <tr key={team._id}>
                    <td>{team.TEAM_ID}</td>
                    <td>{team.TEAM_NAME}</td>
                    {team.PLAYERS.slice(0, tournament.TEAM_SIZE).map((player, index) => (
                      <td key={index}>
                        {player.NAME} {player.USERID === team.CREATED_BY ? '(C)' : ''}
                      </td>
                    ))}
                    <td>{team.isOrganizer ? 'Organizer Dashboard' : 'App'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div></div>
          )}
        </div>

      </div>
    </>
  );
}

export default TeamEventDetails;
