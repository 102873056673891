import React from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import toast from 'react-hot-toast';
import logo from './images/logo.png'
import { NavLink, useLocation } from 'react-router-dom';
import { useUrl } from './UrlContext';

const Sidebar = () => {
  const { pathname } = useLocation(); // Get the current pathname from react-router-dom

  // Function to determine if a NavLink is active based on the current pathname
  const isNavLinkActive = (path) => {
    return pathname === path;
  };

  const activeStyle = {
    color: '#e55855',
    fontSize: '20px',
    transform: 'scale(1.5)',
    transition: 'fontSize 0.3s, transform 0.3s',
  };

  const { setIsLoggedIn } = useUrl();
  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    toast.success('Successfully Logged Out');
  }
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial', color: 'black', boxShadow: '0px 0px 4px 4px rgba(0, 0, 0, 0.08)' }}>
      <CDBSidebar textColor="black" backgroundColor="white">
        <CDBSidebarHeader style={{ background: 'black', color: '#e55855' }} prefix={<i className="fa fa-bars fa-large" style={{ marginTop: '16px' }}></i>} >
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: 'inherit', display: 'flex', alignItems: 'center', marginTop: '0px' }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ maxWidth: '100%', width: '50px', height: 'auto', marginRight: '10px' }}
            />
            Ardent Sport
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content" style={{ fontWeight: 'bold', fontSize: '18px', fontFamily: 'Arial, sans-serif' }}>
          <CDBSidebarMenu>
            <NavLink to="/" style={isNavLinkActive('/') ? activeStyle : null}>
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/tournament" style={isNavLinkActive('/tournament') ? activeStyle : null}>
              <CDBSidebarMenuItem icon="trophy">Tournaments</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/user" style={isNavLinkActive('/user') ? activeStyle : null}>
              <CDBSidebarMenuItem icon="user">Users</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/analytics" style={isNavLinkActive('/analytics') ? activeStyle : null}>
              <CDBSidebarMenuItem icon="chart-line">Analytics</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/notifications" style={isNavLinkActive('/notifications') ? activeStyle : null}>
              <CDBSidebarMenuItem icon="exclamation-circle">Notifications</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/Update" style={isNavLinkActive('/Update') ? activeStyle : null}>
              <CDBSidebarMenuItem icon="envelope">SendNotification</CDBSidebarMenuItem>
            </NavLink>
            <NavLink to="/eventNews" style={isNavLinkActive('/eventNews') ? activeStyle : null}>
              <CDBSidebarMenuItem icon="envelope">Event News</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            style={{
              padding: '20px 5px',
            }}
          >
            <button style={{ color: "#e55855", fontWeight: "bolder" }} className="download-button" onClick={logout}>
              Logout
            </button>
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
