import React, { useEffect, useState } from 'react';
import axios from '../Axios';

const Fixture = (props) => {
  const { fixtureId, players } = props;
  const [roundsData, setRoundsData] = useState([]);

  useEffect(() => {
    const fetchPlayersData = async (round) => {
      try {
        const response = await axios.get(`/getMatchesPlayer?TOURNAMENT_ID=${fixtureId}&ROUND=${round}`);
        return response.data.PLAYERS;
      } catch (error) {
        console.error('Error fetching players data:', error);
        return [];
      }
    };
    // let cnt=0,n=players;
    // while(n > 0){
    //   cnt++;
    //   n = n/2;
    // }
    const fetchTournamentData = async () => {
      const fetchedRoundsData = [];
      for (let round = 1; round < players / 2; round++) {
        const playersData = await fetchPlayersData(round);
        fetchedRoundsData.push(playersData);
      }
      setRoundsData(fetchedRoundsData);
    };

    fetchTournamentData();
  }, [fixtureId, players]);

  return (
    <div style={{ overflowY: 'auto', maxHeight: '700px' }}>
      <h1>Tournament Fixture ID: {fixtureId}</h1>
      <table >
        <thead>
          <tr>
            <th>Round</th>
            {roundsData.map((roundData, index) => (
              <th key={index}>Round {index + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: Math.ceil(players / 2) }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              <td>{rowIndex + 1}</td>
              {roundsData.map((roundData, colIndex) => {
                const playerIndex = rowIndex * 2;
                const player1 = roundData[playerIndex] || {};
                const player2 = roundData[playerIndex + 1] || {};
                return (
                  <td key={colIndex}>
                    {player1.NAME} VS {player2.NAME}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Fixture;