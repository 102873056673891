
import React from "react";

const Loadmore=()=>{
    const loadingSpinnerContainerStyle = {
        display: 'flex',
        justifyContent: 'center', // Horizontally center the content
        alignItems: 'center',     // Vertically center the content
        height: '10vh',
        
                // Set the container to full viewport height
      };
      
      const loadingSpinnerStyle = {
        width: '30px',
        height: '30px',
        border: '4px solid rgba(0, 0, 0, 0.2)',
        borderTop: '4px solid  #ff6347',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
      };

      return(
        <div>
         <div style={loadingSpinnerContainerStyle}>
          <div style={loadingSpinnerStyle}>
            {/* Add keyframesSpin to the style */}
            <style>
              {`
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
            </style>
          </div>
        </div>
        </div>
      )


}

export default Loadmore;