import React, { useEffect, useState } from 'react';
import axios from '../Axios';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import './SummaryReport.css';

function SummaryReport() {
    const { id } = useParams();
    const [allentry, setAllEntry] = useState('');
    const [loading, setLoading] = useState(true);
    const [tournamentName, setTournamentName] = useState('');
    const [entryFee, setEntryFee] = useState('');
    const [organizerCount, setOrganizerCount] = useState(0);
    const [settledorganizerCount, setsettledOrganizerCount] = useState(0);
    const [appCount, setAppCount] = useState(0);
    const [settledappCount, setsettledAppCount] = useState(0);
    const [totalEntries, setTotalEntries] = useState(0);
    const [settledtotalEntries, setsettledTotalEntries] = useState(0);
    const [totalAmountCollected, setTotalAmountCollected] = useState(0);
    const [settledtotalAmountCollected, setsettledTotalAmountCollected] = useState(0);
    const [totalConvenienceFee, setTotalConvenienceFee] = useState(0);
    const [settledtotalConvenienceFee, setsettledTotalConvenienceFee] = useState(0);
    const [settlementAmount, setSettlementAmount] = useState(0);
    const [settledsettlementAmount, setsettledSettlementAmount] = useState(0);
    const [convenienceFeeApp, setConvenienceFeeApp] = useState(5); // Default value 5
    const [convenienceFeeOrganizer, setConvenienceFeeOrganizer] = useState(2.5); // Default value 2.5
    const [settledconvenienceFeeApp, setsettledConvenienceFeeApp] = useState(0); // Default value 5
    const [settledconvenienceFeeOrganizer, setsettledConvenienceFeeOrganizer] = useState(0); // Default value 2.5
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [OrganizerAmount, setOrganizerAmount] = useState(0);
    const [settledOrganizerAmount, setsettledOrganizerAmount] = useState(0);

    useEffect(() => {
        axios.get(`/allEntries/${id}`)
            .then((response) => {
                const entries = response.data.filter(entry => entry.PAYMENT_SETTLED === false);
                const settledentries = response.data.filter(entry => entry.PAYMENT_SETTLED === true);
                let allEntries = response.data.reverse();
                setAllEntry(allEntries);
                if (allEntries.length > 0) {
                    const firstEntry = allEntries[0];
                    setTournamentName(firstEntry.TOURNAMENT_NAME);
                    setEntryFee(firstEntry.ENTRY_FEE);
                    const totalEntryFee = entries
                        .filter(entry => entry.STATUS === true)
                        .reduce((total, entry) => {
                            return total + parseInt(entry.ENTRY_FEE);
                        }, 0);

                    const settledtotalEntryFee = settledentries
                        .filter(entry => entry.STATUS === true)
                        .reduce((total, entry) => {
                            return total + parseInt(entry.ENTRY_FEE);
                        }, 0);

                    const OrganizerAmount = entries
                        .filter(entry => entry.STATUS === false)
                        .reduce((total, entry) => {
                            return total + parseInt(entry.ENTRY_FEE);
                        }, 0);

                    const settledOrganizerAmount = settledentries
                        .filter(entry => entry.STATUS === false)
                        .reduce((total, entry) => {
                            return total + parseInt(entry.ENTRY_FEE);
                        }, 0);
                    const fee = settledentries.reduce((total, entry) => {
                            if(entry.STATUS === true)
                            setsettledConvenienceFeeApp(entry.CONVINIECE_FEE);
                            else
                            setsettledConvenienceFeeOrganizer(entry.CONVINIECE_FEE);
                            return total + parseFloat((entry.ENTRY_FEE * entry.CONVINIECE_FEE / 100).toFixed(2));
                    }, 0);
                    const organizerCount = entries.filter(entry => entry.STATUS === false).length;
                    const appCount = entries.filter(entry => entry.STATUS === true).length;
                    const totalEntries = organizerCount + appCount;
                    const totalAmountCollected = totalEntryFee;
                    const totalConvenienceFee = (totalAmountCollected * (convenienceFeeApp / 100)) + (OrganizerAmount * (convenienceFeeOrganizer / 100));
                    const settlementAmount = totalAmountCollected - totalConvenienceFee;
                    const settledorganizerCount = settledentries.filter(entry => entry.STATUS === false).length;
                    const settledappCount = settledentries.filter(entry => entry.STATUS === true).length;
                    const settledtotalEntries = settledorganizerCount + settledappCount;
                    const settledtotalAmountCollected = settledtotalEntryFee;
                    const settledsettlementAmount = settledtotalAmountCollected - fee;
                    setAppCount(appCount);
                    setOrganizerCount(organizerCount);
                    setTotalEntries(totalEntries);
                    setTotalAmountCollected(totalAmountCollected);
                    setTotalConvenienceFee(totalConvenienceFee);
                    setSettlementAmount(settlementAmount);
                    setsettledAppCount(settledappCount);
                    setsettledOrganizerCount(settledorganizerCount);
                    setsettledTotalEntries(settledtotalEntries);
                    setsettledTotalAmountCollected(settledtotalAmountCollected);
                    setsettledSettlementAmount(settledsettlementAmount);
                    setOrganizerAmount(OrganizerAmount);
                    setsettledOrganizerAmount(settledOrganizerAmount);
                    setsettledTotalConvenienceFee(fee);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [id, entryFee, convenienceFeeApp, convenienceFeeOrganizer]);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }
    const categoryMappings = {
        MS: "Men's Singles",
        WS: "Women's Singles",
        MD: "Men's Doubles",
        WD: "Women's Doubles",
        MixD: "Mixed Doubles",
        BD: "Boys Doubles",
        BS: "Boys Singles",
        GS: "Girls Singles",
        GD: "Girls Doubles"
    };
    const tournamentParts = tournamentName.split('-');
    const tournament = tournamentParts.slice(0, -2).join('-');
    const openModal = () => {
        if (isModalOpen === false)
            setIsModalOpen(true);
        else
            setIsModalOpen(false);
    };

    const settlePayment = async () => {
        try {
            const payment = window.confirm('Are you sure you want to settle this payment?');
            if (payment) {
                const response = await axios.get(`/settlePayment/${id}/${convenienceFeeApp}/${convenienceFeeOrganizer}`);
                if (response.status === 200) {
                    toast.success("Payment Settled Successfully.");
                    window.location.reload();
                }
            }
        } catch (error) {
            toast.error("Error while setting payment.");
            console.error("Error while setting payment", error);
        }
    };
    const Download = async () => {
        try {
          const response = await axios.get(`/downloadData/${id}`, { responseType: 'blob' });
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = blobURL;
          a.download = `${tournament}.pdf`;
          a.click();
          URL.revokeObjectURL(blobURL);
        } catch (error) {
          console.error('Error downloading data:', error);
        }
      };
    return (
        <>
            <div>
                <h1 className="head" style={{ color: "black" }}>{tournament}</h1>
                {allentry.length > 0 ? (
                    <>
                        <div className="summary-container">
                            <h1 className="head2">Unsettled Report</h1>
                            <div className="summary-details">
                                <div className="summary-field">
                                    <p className="label">Booking through Payment Gateway:</p>
                                    <p className="value">{appCount}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Booking through Organizer:</p>
                                    <p className="value">{organizerCount}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Total Entries:</p>
                                    <p className="value">{totalEntries}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Total Amount Collected:</p>
                                    <p className="value">Rs. {totalAmountCollected}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Amount Collected (Organizer):</p>
                                    <p className="value">Rs. {OrganizerAmount}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Convenience Fee % (App) :</p>
                                    <input
                                        className="value input"
                                        type="number"
                                        value={convenienceFeeApp}
                                        onChange={(e) => setConvenienceFeeApp(Number(e.target.value))}
                                    />
                                </div>
                                <div className="summary-field">
                                    <p className="label">Convenience Fee % (Organizer):</p>
                                    <input
                                        className="value input"
                                        type="number"
                                        value={convenienceFeeOrganizer}
                                        onChange={(e) => setConvenienceFeeOrganizer(Number(e.target.value))}
                                    />
                                </div>
                                <div className="summary-field">
                                    <p className="label">Total Convenience Fee: <i
                                        className="fas fa-info-circle info-icon"
                                        onClick={openModal}
                                    ></i></p>
                                    <p className="value">Rs. {totalConvenienceFee.toFixed(2)}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Settlement Amount:</p>
                                    <div className="value">
                                        Rs. {settlementAmount.toFixed(2)}
                                        <button className="right-button btn2" onClick={settlePayment}>Settle Payment</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "10px" }} className="summary-container">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <h1 className="head2" style={{ textAlign: "center", flex: "1" }}>
                                    Settled Report
                                </h1>
                                <button onClick={Download} style={{ alignSelf: "flex-end", marginBottom: "15px", marginRight: '20px' }}>
                                    <i style={{ fontSize: "28px" }} className="fas fa-download"></i>
                                </button>
                            </div>
                            <div className="summary-details">
                                <div className="summary-field">
                                    <p className="label">Booking through Payment Gateway:</p>
                                    <p className="value">{settledappCount}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Booking through Organizer:</p>
                                    <p className="value">{settledorganizerCount}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Total Entries:</p>
                                    <p className="value">{settledtotalEntries}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Total Amount Collected:</p>
                                    <p className="value">Rs. {settledtotalAmountCollected}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Amount Collected (Organizer):</p>
                                    <p className="value">{settledOrganizerAmount}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Convenience Fee % (App) :</p>
                                    <p className="value">{settledconvenienceFeeApp}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Convenience Fee % (Organizer):</p>
                                    <p className="value">{settledconvenienceFeeOrganizer}</p>
                                </div>
                                <div className="summary-field">
                                    <p className="label">Total Convenience Fee: <i
                                        className="fas fa-info-circle info-icon"
                                        onClick={openModal}
                                    ></i></p>
                                    <p className="value">Rs. {settledtotalConvenienceFee.toFixed(2)}</p>
                                </div>
                                <div className="summary-field">

                                    <p className="label">Settled Amount:</p>
                                    <div className="value">
                                        Rs. {settledsettlementAmount.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <h3 style={{ color: "black", textAlign: "center", marginTop: "10%" }}>No entries in this tournament/category</h3>
                )}
                <div>
                    {allentry.length > 0 ? (
                        <table className="summary-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>USERID</th>
                                    <th>User Type</th>
                                    <th>Category</th>
                                    <th>Entry Fee</th>
                                    <th>Booking Through</th>
                                    <th>Booking Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allentry.map((user) => (
                                    <tr key={user._id} className={user.PAYMENT_SETTLED ? 'paid' : ''}>
                                        <td>{user.NAME}</td>
                                        <td>{user.USERID}</td>
                                        <td>{user.USERID.length === 10 ? "Registered" : "Unregistered"}</td>
                                        <td>
                                            {user.TOURNAMENT_NAME
                                                .split('-')
                                                .slice(-2)
                                                .map((word, index) => {
                                                    if (categoryMappings.hasOwnProperty(word)) {
                                                        return categoryMappings[word];
                                                    } else {
                                                        return word;
                                                    }
                                                })
                                                .reverse()
                                                .join(' ')}
                                        </td>
                                        <td>{user.ENTRY_FEE}</td>
                                        <td>{user.STATUS === true ? "App" : "Organizer"}</td>
                                        <td>
                                            {new Date(user.CREATE_TIMESTAMP).toLocaleString('en-US', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                            }).replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+)\s(AM|PM)/, "$2/$1/$3, $4:$5 $6")}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div></div>
                    )}
                </div>

                {isModalOpen && (
                    <div
                        className="modal-overlay"
                        onClick={openModal}
                        style={{ top: '300px', left: '600px', position: 'absolute', zIndex: 1000, overflowY: 'hidden' }}
                    >
                        <div className="modal-content" style={{ height: "180px", width: "400px" }}>
                            <div className="modal-body">
                                <p>Convenience Fee  = ( Total amt collected * App Convenience Fee%) + ( Amount Collected (Organizer) * Organizer Convenience Fee%)</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default SummaryReport;
