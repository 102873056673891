import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import axios from "../Axios";
import toast from "react-hot-toast";
import "./EventNews.css";
// import { format, parseISO } from "date-fns";
import Loadmore from "./Pagination";

const EventNews = () => {
  const [title, settitle] = useState("");
  const [desc, setdesc] = useState("");
  const [link, setlink] = useState("");
  const [file, setfile] = useState(null);
  const [allnews, setallnews] = useState([]);
  const [filallnews, setfilallnews] = useState([]);
  const [loading, setloading] = useState(false);
  const [updateId, setupdateId] = useState(null);
  const [image, setimage] = useState("");
  const [sport, setsport] = useState("General");
  const [websitename, setwebsitename] = useState("");
  const [selectedOption, setSelectedOption] = useState("all");
  const [filtercat, setfiltercat] = useState("all");
  const [inputValue, setInputValue] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleImageUpload = async (e) => {
    const imageFile = e.target.files[0];
    if (!imageFile) {
      return;
    }
    const options = {
      maxSizeMB: 1, // Maximum size in MB after compression
      maxWidthOrHeight: 1024, // Maximum width or height after compression
      useWebWorker: true, // Use Web Workers for faster compression (recommended)
    };
    try {
      const compressedImage = await imageCompression(imageFile, options);

      const compressedfile = new File([compressedImage], imageFile.name, {
        type: imageFile?.type,
      });
      setfile(compressedfile);
    } catch (error) {
      console.error("Error compressing image:", error);
      setfile(null);
    }
  };
  const handleReset = (e) => {
    settitle("");
    setdesc("");
    setlink("");
    setwebsitename("");
    setupdateId(null);
    setfile(null);
    setSelectedUsers([])
    setInputValue("")
    setSelectedOption("all")
  };

  const addEventNews = async () => {
    try {
      if (
        file == null ||
        title.length < 1 ||
        desc.length < 1 ||
        link.length < 1
      ) {
        toast.error("All field are mandotary");
        return;
      }
      let notifycat = "";
      let notifyval = "";
      if (selectedOption === "tournamentid") {
        notifycat = "tournamentid";
        notifyval = inputValue.trim();
      } else if (selectedOption === "users") {
        notifycat = "users";
        notifyval = selectedUsers;
      } else if (
        selectedOption === "Badminton" ||
        selectedOption === "Table Tennis" ||
        selectedOption === "Squash" ||
        selectedOption === "Lawn Tennis"
      ) {
        notifycat = selectedOption;
        notifyval = selectedOption;
      }

      console.log(notifycat);
      console.log(notifyval);
      setloading(true);
      const data = new FormData();
      data.append("title", title);
      data.append("description", desc);
      data.append("link", link);
      data.append("sport", sport);
      data.append("file", file);
      data.append("websitename", websitename);
      data.append("notifycat", notifycat);
      data.append("notifyval", notifyval);
      const res = await axios.post(`/addnews`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          // "authorization": `Bearer ${token}`, // Include the bearer token here
        },
      });
      if (res.status === 200) {
        setallnews((ev) => [res?.data?.news, ...ev]);
        setfiltercat("all")
        handleReset();
        toast.success(res?.data?.Message);
      } else {
        toast.error(res?.data?.Message);
      }
    } catch (error) {
      toast.error("Some error occured!");
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const fetchAllNews = async () => {
    setloading(true);
    try {
      const res = await axios.get(`/getnews`, {
        headers: {
          "Content-Type": "application/json",
          // "authorization": `Bearer ${token}`, // Include the bearer token here
        },
      });

      if (res.status === 200) {
        console.log(res.data);
        setallnews((ev) => [...res?.data?.allnews]);
        setfilallnews((ev) => [...res?.data?.allnews]);
        toast.success(res?.data?.Message);
      } else {
        toast.error(res?.data?.Message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Some error occured!");
    } finally {
      setloading(false);
    }
  };

  const deleteNews = async (id, image) => {
    setloading(true);
    try {
      const res = await axios.post(
        `/deletenews`,
        { id, image },
        {
          headers: {
            "Content-Type": "application/json",
            // "authorization": `Bearer ${token}`, // Include the bearer token here
          },
        }
      );

      if (res.status === 200) {
        const filterNews = allnews?.filter((item) => item._id !== id);
        const filterNewscat = filallnews?.filter((item) => item._id !== id);
        setallnews((ev) => filterNews);
        setfilallnews((ev) => filterNewscat);
        toast.success(res?.data?.Message);
      } else {
        toast.error(res?.data?.Message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Some error occured!");
    } finally {
      setloading(false);
    }
  };

  const onUpdateClick = (
    id,
    image,
    linklocal,
    titlelocal,
    descriptionlocal,
    sprt,
    websitenamelocal
  ) => {
    if (loading) return;
    setupdateId(id);
    setimage(image);
    settitle(titlelocal);
    setdesc(descriptionlocal);
    setlink(linklocal);
    setsport(sprt);
    setwebsitename(websitenamelocal);
  };

  const eventupdateHandle = async () => {
    if (updateId == null || loading) return;
    setloading(true);

    try {
      if (
        file == null ||
        title.length < 1 ||
        desc.length < 1 ||
        link.length < 1 ||
        sport.length < 1
      ) {
        toast.error("All field are mandotary");
        return;
      }
      const data = new FormData();
      data.append("title", title);
      data.append("description", desc);
      data.append("image", image);
      data.append("id", updateId);
      data.append("link", link);
      data.append("sport", sport);
      data.append("file", file);
      data.append("websitename", websitename);
      const res = await axios.post(`/updatenews`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          // "authorization": `Bearer ${token}`, // Include the bearer token here
        },
      });
      if (res.status === 200) {
        const filterNews = allnews.filter((item) => item._id !== updateId);
        setallnews((ev) => [res?.data?.updatedNews, ...filterNews]);
        toast.success(res?.data?.Message);
        cancelUpdate();
      } else {
        toast.error(res?.data?.Message);
      }
    } catch (error) {
      toast.error("Some error occured!");
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const cancelUpdate = () => {
    if (loading) return;
    setupdateId(null);
    setimage("");
    settitle("");
    setdesc("");
    setlink("");
    setsport("");
    setwebsitename("");
  };

  const handleAddUser = () => {
    if (inputValue) {
      setSelectedUsers([...selectedUsers, inputValue]);
      setInputValue(""); // Clear input after adding user
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setInputValue(""); // Clear input when changing the selected option
  };

  const handleRemoveUser = (user) => {
    const updatedUsers = selectedUsers.filter((u) => u !== user);
    setSelectedUsers(updatedUsers);
  };

  const filterNewsByCat = (e) => {
    const ct = e.target.value;
    setfiltercat(ct);
    if (ct === "All") {
      setfilallnews(allnews);
      return;
    }
    const news = allnews?.filter((ns) => ns?.SPORT === ct);
    setfilallnews(news);
  };

  useEffect(() => {
    fetchAllNews();
  }, []);

  return (
    <div>
      <h1>Create an event news</h1>
      <div className="input-container">
        <label>Title:</label>
        <input
          type="text"
          name="title"
          placeholder="Enter title"
          value={title}
          onChange={(e) => settitle(e.target.value)}
        />
      </div>

      <div className="input-container">
        <label>Description:</label>
        <textarea
          name="body"
          placeholder="Enter description"
          value={desc}
          onChange={(e) => setdesc(e.target.value)}
        ></textarea>
      </div>
      <div className="input-container">
        <label>Websitename:</label>
        <input
          type="text"
          name="title"
          placeholder="Enter website name"
          value={websitename}
          onChange={(e) => setwebsitename(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label>Link:</label>
        <input
          type="text"
          name="title"
          placeholder="Enter link"
          value={link}
          onChange={(e) => setlink(e.target.value)}
        />
      </div>
      <div className="input-container">
        <label>
          Add Label:
          <select
            value={sport}
            onChange={(e) => setsport(e.target.value)}
            style={{
              margin: "10px",
              width: "10rem",
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              outline: "none",
            }}
          >
            <option value="General">General</option>
            <option value="Badminton">Badminton</option>
            <option value="Table Tennis">Table Tennis</option>
            <option value="Squash">Squash</option>
            <option value="Lawn Tennis">Lawn Tennis</option>
          </select>
        </label>
      </div>
      <div className="input-container">
        <label>
          Whom to send?
          <select value={selectedOption} onChange={handleOptionChange}>
            <option value="all">All</option>
            <option value="Badminton">Badminton</option>
            <option value="Table Tennis">Table Tennis</option>
            <option value="Squash">Squash</option>
            <option value="Lawn Tennis">Lawn Tennis</option>
            <option value="users">Users</option>
            <option value="tournamentid">Tournament ID</option>
          </select>
        </label>
        {/* Input fields based on selected option */}
        {selectedOption && (
          <div className="input-container">
            {selectedOption === "users" && (
              <div>
                <label>Add Users:</label>
                <input
                  type="text"
                  name="users"
                  placeholder="Enter user ID"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <button
                  style={{
                    padding: "10px 20px",
                    marginTop: "10px",
                    backgroundColor: "#000",
                    color: "#ff6347",
                    border: "none",
                    borderRadius: "10%",
                  }}
                  onClick={handleAddUser}
                >
                  Add
                </button>
              </div>
            )}
            {selectedOption === "tournamentid" && (
              <input
                name={selectedOption}
                type="text"
                placeholder={`Enter ${selectedOption}`}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            )}
          </div>
        )}

        {/* Display selected users */}
        {selectedUsers.length > 0 && (
          <div className="selected-people-container">
            <h3>Selected Users:</h3>
            <ul>
              {selectedUsers.map((user, index) => (
                <li key={index}>
                  {user}
                  <button onClick={() => handleRemoveUser(user)}>Remove</button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="input-container">
        <label>Upload Image:</label>
        <input
          type="file"
          name="image"
          accept="image/*" // Allow only image files
          onChange={handleImageUpload}
        />
      </div>
      {updateId == null ? (
        <div className="button-container">
          {loading ? (
            <Loadmore />
          ) : (
            <button onClick={addEventNews}>Submit</button>
          )}
          <button onClick={handleReset}>Reset</button>
        </div>
      ) : (
        <div className="button-container">
          {loading ? (
            <Loadmore />
          ) : (
            <button onClick={eventupdateHandle}>Update</button>
          )}

          <button onClick={cancelUpdate}>Cancel</button>
        </div>
      )}

      <div>
        <h1>All News</h1>
        <div className="input-container">
          <label>
            Filter News:
            <select
              value={filtercat}
              onChange={filterNewsByCat}
              style={{
                margin: "10px",
                width: "10rem",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                outline: "none",
              }}
            >
              <option value="All">All</option>
              <option value="General">General</option>
              <option value="Badminton">Badminton</option>
              <option value="Table Tennis">Table Tennis</option>
              <option value="Squash">Squash</option>
              <option value="Lawn Tennis">Lawn Tennis</option>
            </select>
          </label>
        </div>
        <div className="grid grid-cols-1 gap-10 p-10 md:grid-cols-2 lg:grid-cols-3">
          {filallnews.map((item, key) => {
            return (
              <Article
                key={key}
                date={item?.createdAt}
                desc={item?.DESCRIPTION}
                id={item?._id}
                image={item?.IMAGE}
                link={item?.LINK}
                title={item?.TITLE}
                sport={item?.SPORT}
                website_name={item?.WEBSITE_NAME}
                deleteNews={deleteNews}
                onUpdateClick={onUpdateClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

function Article({
  image,
  link,
  title,
  desc,
  id,
  sport,
  date,
  deleteNews,
  onUpdateClick,
  website_name,
}) {
  return (
    <article className="flex flex-col transition-all duration-200 ease-out rounded-lg shadow-lg bg-slate-100 hover:scale-105 hover:shadow-xl hover:bg-slate-200">
      {image && (
        <img
          src={`${image}`}
          alt={image}
          className="object-cover w-full h-56 rounded-t-lg shadow-md"
        />
      )}
      <div className="flex flex-col flex-1">
        <div className="flex flex-col flex-1">
          <h2 className="mx-2 font-serif font-bold text-black">{title}</h2>
          <section className="flex-1 ">
            <p className="mx-2 text-lg text-black line-clamp-5">{desc}</p>
          </section>
          <footer className="flex ml-auto space-x-1 text-xs italic text-right text-gray-400">
            <p className="mx-1">{"ArdentSports"} -</p>
            <p className="mx-2">{date}</p>
          </footer>
          <footer className="flex ml-auto space-x-1 text-xs italic text-right text-gray-400">
            <p className="mx-1">{sport}</p>
          </footer>
        </div>
        <h6 className="ml-3 text-black">
          Read more here{" "}
          <a href={link} target="_blank" rel="noreferrer">
            {" "}
            {website_name}
          </a>{" "}
        </h6>
        <div className="flex justify-between p-2 space-x-3">
          <button
            className="w-full h-10 bg-orange-400 rounded-lg cursor-pointer dark:text-gray-900 hover:bg-orange-500 "
            onClick={() => {
              deleteNews(id, image);
            }}
          >
            Delete
          </button>
          <button
            className="w-full h-10 bg-orange-400 rounded-lg cursor-pointer dark:text-gray-900 hover:bg-orange-500"
            onClick={() => {
              onUpdateClick(id, image, link, title, desc, sport, website_name);
            }}
          >
            Update
          </button>
        </div>
        {/* <ReadMoreButton article={link} /> */}
      </div>
    </article>
  );
}

// function ReadMoreButton({ link }) {
//   return (
//     <a
//       href={link}
//       className="flex items-center justify-center h-10 bg-orange-400 rounded-b-lg cursor-pointer dark:text-gray-900 hover:bg-orange-500"
//       target="_blank"
//       rel="noreferrer"
//     >
//       Read More
//     </a>
//   );
// }

// const OneNews = ({
//   image,
//   link,
//   title,
//   desc,
//   id,
//   date,
//   deleteNews,
//   onUpdateClick,
// }) => {
//   return (
//     <div className="main_container">
//       <img
//         className="image"
//         alt={image}
//         src={`${baseUrl}/loadimage/${image}`}
//       ></img>
//       <div className="text_body">
//         <h1>{title}</h1>
//         <p>{desc}</p>
//       </div>
//       <div>
//         <h4>
//           Read more at <a href={link}>here</a>
//         </h4>
//         <h5>{date}</h5>
//       </div>
//       <div className="button-container">
//         <button
//           onClick={() => {
//             deleteNews(id, image);
//           }}
//         >
//           Delete
//         </button>
//         <button
//           onClick={() => {
//             onUpdateClick(id, image, link, title, desc);
//           }}
//         >
//           Update
//         </button>
//       </div>
//     </div>
//   );
// };

export default EventNews;
