import React, { useEffect, useState, useRef, useCallback } from 'react';
import './Users.css';
import axios from '../Axios';
import { FaTrash, FaCheck, FaRegCircle } from 'react-icons/fa';
import profile from '../images/profile.jpeg';
import { toast } from 'react-hot-toast';
import Loadmore from './Pagination';
import { useUrl } from '../UrlContext';
import axios2 from 'axios';
import debounce from 'lodash/debounce';

const Tab = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div>
      {tabs.map((tab) => (
        <button
          key={tab}
          onClick={() => onTabChange(tab)}
          style={{
            backgroundColor: activeTab === tab ? 'black' : 'white',
            color: activeTab === tab ? '#e55855' : 'black',
            marginBottom: '10px',
            marginLeft: '10px',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '16px',
            cursor: 'pointer',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s ease',
          }}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

const User = () => {
  const [users, setUsers] = useState([]);
  const [Unregisteredusers, setUnregisterdUsers] = useState([]);
  const [activeTab, setActiveTab] = useState('Registered User');
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allDataFetched, setAllDataFetched] = useState(false);
  const [intialLoading, setIntialLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [dobOrder, setDobOrder] = useState(-1);
  const [genderOrder, setGenderOrder] = useState(-1);
  const [cityOrder, setCityOrder] = useState(-1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const lastTableRowRef = useRef(null);

  const { googleAccessToken } = useUrl();

  async function addContact(accessToken, contactDetails, USER_ID) {
    const apiUrl = 'https://people.googleapis.com/v1/people:createContact';
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
  
    try {
      const response = await axios2.post(apiUrl, contactDetails, { headers });
      axios.get(`/setContactFlagTrue/${USER_ID}`)
        .then((response) => {
          
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
      console.log('Contact created:', response.data);
      toast.success(`Contact created: ${response.data.names[0].givenName}`);
    } catch (error) {
      console.error('Failed to create contact:', error);
      toast.error(`Failed to create contact. ${error.message}`);
    }
  }

  const toggleUserSelection = (user) => {
    if (selectedUsers.includes(user.USERID)) {
      setSelectedUsers(selectedUsers.filter(id => id !== user.USERID));
    } else {
      setSelectedUsers([...selectedUsers, user.USERID]);
    }
  };

  const isSelected = (userId) => selectedUsers.includes(userId);

  const rowStyle = (userId) => ({
    backgroundColor: isSelected(userId) ? '#f0f0f0' : 'white',
    cursor: 'pointer',
  });

  const saveSelectedUsersToContacts = async () => {
    const selectedUserDetails = users.filter(user => selectedUsers.includes(user.USERID));
    for (const user of selectedUserDetails) {
      try {
        await addContact(googleAccessToken, {
          names: [{
              givenName: user.NAME + " " + user?.CITY + " " + user?.INTERESTED_SPORTS.join(' ')
          }],
          phoneNumbers: [{ value: user.PHONE }],
        }, user.USERID);
        
      } catch (error) {
        console.error(`Failed to save ${user.NAME}:`, error);
      }
    }
    setSelectedUsers([]);
    window.location.reload();
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (!allDataFetched) {
      if (intialLoading) {
        loadMoreData(pageNo);
      }
    }
  // eslint-disable-next-line
  }, [pageNo, intialLoading, dobOrder, genderOrder, cityOrder, allDataFetched]);

  const loadMoreData = (page) => {
    setIsLoading(true);
    setTimeout(() => {
    axios.get(`/getAllUsers?pageNo=${page}&search=${search}&searchTerm=${searchTerm}&dob=${dobOrder}&city=${cityOrder}&gender=${genderOrder}`)
      .then((response) => {
        const newData = response.data.USERS;
        if (newData.length === 0) {
          setAllDataFetched(true);
        } else {
          setUsers((prevData) => [...prevData, ...newData]);
          setPageNo(page + 1);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
    }, 1000);
  };

  useEffect(() => {
    setIntialLoading(true);
  }, []);

  useEffect(() => {
    axios.get('/getUnregisteredUsers')
      .then((response) => {
        setUnregisterdUsers(response.data.USERS);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const formatDate = (timestamp) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(timestamp).toLocaleDateString('en-US', options);
  };

  const formatTime = (timestamp) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(timestamp).toLocaleTimeString('en-US', options);
  };

  function capitalizeFirstLetter(str) {
    if (!str) return str;
    const words = str.trim().split(/\s+/);
    const capitalizedWords = words.map((word) => {
      if (!word) return '';
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    const capitalizedString = capitalizedWords.join(' ');
    return capitalizedString;
  }

  const handleSort = (column) => {
    if (column === 'DOB') {
      if (dobOrder === -1 || dobOrder === 0) {
        setDobOrder(1);
        setGenderOrder(-1);
        setCityOrder(-1);
        setUsers([]);
        setAllDataFetched(false);
        setPageNo(1);
      } else {
        setDobOrder(0);
        setGenderOrder(-1);
        setCityOrder(-1);
        setUsers([]);
        setAllDataFetched(false);
        setPageNo(1);
      }
    } else if (column === 'GENDER') {
      if (genderOrder === -1 || genderOrder === 0) {
        setGenderOrder(1);
        setDobOrder(-1);
        setCityOrder(-1);
        setUsers([]);
        setAllDataFetched(false);
        setPageNo(1);
      } else {
        setGenderOrder(0);
        setDobOrder(-1);
        setCityOrder(-1);
        setUsers([]);
        setAllDataFetched(false);
        setPageNo(1);
      }
    } else if (column === 'CITY') {
      if (cityOrder === -1 || cityOrder === 0) {
        setCityOrder(1);
        setGenderOrder(-1);
        setDobOrder(-1);
        setUsers([]);
        setAllDataFetched(false);
        setPageNo(1);
      } else {
        setCityOrder(0);
        setGenderOrder(-1);
        setDobOrder(-1);
        setUsers([]);
        setAllDataFetched(false);
        setPageNo(1);
      }
    }
  };

  const renderSortArrow = (column) => {
    return <span>&nbsp;&nbsp;&#8645;</span>;
  };

  const handleDeleteUser = (userId) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this user?');

    if (shouldDelete) {
      axios.post('/removeUser', { USERID: userId })
        .then((response) => {
          toast.success('User deleted successfully:', response.data);
          window.location.reload();
        })
        .catch((error) => {
          console.error('Error deleting user:', error);
        });
    }
  };

  const handleDeleteUnregisteredUser = (userId) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this user?');

    if (shouldDelete) {
      axios.post('/removeUnregisteredUser', { USERID: userId })
        .then((response) => {
          console.log('User deleted successfully:', response.data);
          window.location.reload();
        })
        .catch((error) => {
          console.error('Error deleting user:', error);
        });
    }
  };

  function calculateAge(dob) {
    if (dob === '')
      return null;
    const birthDate = new Date(dob.split("/").reverse().join("-"));
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    if (today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

   //eslint-disable-next-line
  const debouncedHandleSearch = useCallback(debounce((str) => {
    if(str===''){
      setSearch(0);
    }
    else{
      setSearch(1);
    }
    setUsers([]);
    setSearchTerm(str);
    setAllDataFetched(false);
    setPageNo(1);
  }, 500), []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    debouncedHandleSearch(e.target.value);
  };

  const cellStyle = {
    borderBottom: '1px solid #ddd',
    padding: '8px',
  };

  const nowrapCellStyle = {
    ...cellStyle,
    whiteSpace: 'nowrap',
  };

  return (
    <div>
      <div>
        <Tab
          tabs={['Registered User', 'Unregistered User']}
          activeTab={activeTab}
          onTabChange={(tab) => setActiveTab(tab)}
        />
      </div>
      <div>
        {activeTab === 'Registered User' && (
          <div>
            <div>
              <input
                className="search-input"
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                placeholder={`Search by Phone No. or Name`}
                style={{
                  margin: '10px',
                  padding: '8px',
                  borderRadius: '5px',
                  border: `1px solid ${isFocused ? '#ff0000' : '#ccc'}`,
                  outline: 'none',
                }}
              />
            </div>

            <table className='fixed-table' style={{ color: 'black' }}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th onClick={() => handleSort('DOB')}>
                    DOB {renderSortArrow('DOB')}
                  </th>
                  <th>Age</th>
                  <th onClick={() => handleSort('GENDER')}>
                    Gender {renderSortArrow('GENDER')}
                  </th>
                  <th>Academy</th>
                  <th onClick={() => handleSort('CITY')}>
                    City {renderSortArrow('CITY')}
                  </th>
                  <th>Interested Sports</th>
                  <th>Sign Up Time</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={user._id} ref={index === users.length - 1 ? lastTableRowRef : null} style={rowStyle(user.USERID)} onClick={() => toggleUserSelection(user)}>
                    <td>
                      {user.CONTACT_FLAG ? <FaCheck /> : <FaRegCircle />}
                    </td>
                    <td>
                      <div className="profile-pic">
                        <img
                          src={user.PROFILE_PIC_URL || profile}
                          alt="Profile"
                        />
                      </div>
                    </td>
                    <td style={{ fontWeight: 'bold' }}>{user.NAME + " " + user?.LAST_NAME}</td>
                    <td>{user.PHONE}</td>
                    <td>{user.EMAIL}</td>
                    <td>{user.DOB || ''}</td>
                    <td>{calculateAge(user.DOB || '') || ''}</td>
                    <td>{user.GENDER}</td>
                    <td>{user.SPORTS_ACADEMY || ""}</td>
                    <td>{capitalizeFirstLetter(user.CITY)}</td>
                    <td>{user.INTERESTED_SPORTS.join(", ") || ""}</td>
                    <td style={nowrapCellStyle}>
                      {user.LOGIN_TIMESTAMP ? (
                        <div>
                          {formatDate(user.LOGIN_TIMESTAMP)}<br />
                          {formatTime(user.LOGIN_TIMESTAMP)}
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td onClick={(e) => { e.stopPropagation(); handleDeleteUser(user.USERID); }}>
                      <FaTrash />
                    </td>
                  </tr>
                ))}
              </tbody>
              {isLoading && (<Loadmore />)}
              {allDataFetched && (
                <div style={{ color: 'red', textAlign: 'center', left: "40%" }}>All data has been loaded.</div>
              )}
            </table>

            {/* Display button when users are selected */}
            {selectedUsers.length > 0 && (
              <div style={{ position: 'fixed', bottom: '10px', right: '40%', zIndex: 1000 }}>
                <button style={{ padding: '10px 20px', backgroundColor: '#e55855', color: 'white', borderRadius: '5px', cursor: 'pointer' }} onClick={saveSelectedUsersToContacts}>
                  Save Selected to Contacts
                </button>
              </div>
            )}
          </div>
        )}

        {activeTab === 'Unregistered User' && (
          <table style={{ color: 'black' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>USER_ID</th>
                <th>Phone</th>
                <th>Current Tournaments</th>
              </tr>
            </thead>
            <tbody>
              {Unregisteredusers.map((user) => (
                <tr key={user._id}>
                  <td style={{ fontWeight: 'bold' }}>{user.NAME}</td>
                  <td>{user.USERID}</td>
                  <td>{user?.PHONE}</td>
                  <td>{user.CURRENT_TOURNAMENTS.length}</td>
                  <td onClick={() => handleDeleteUnregisteredUser(user.USERID)}>
                    <FaTrash />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default User;
