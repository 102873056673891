import React, { useState, useEffect } from 'react';
import './Categories.css';
import Fixtures from './Fixtures';
import axios from '../Axios';
import { useParams, Link } from 'react-router-dom';
import { toast } from "react-hot-toast";

const Categories = () => {
  const { tournamentId } = useParams();
  const [tournament, setTournament] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [editModalData, setEditModalData] = useState(null);
  const [editPrizeData, setPrizeData] = useState(null);
  const [FixtureModal, setFixtureModal] = useState(false);
  const [FixtureID, setFixtureID] = useState(null);
  const [players, setplayers] = useState(0);
  const [category, setCategory] = useState('SINGLES');
  const [entryData, setEntryData] = useState(null);
  const [newEntry, setNewEntry] = useState('');
  const [doublePlayer, setDoublePlayer] = useState({
    USERID1: "",
    USERID2: ""
  });

  useEffect(() => {
    axios.get(`/tournament/${tournamentId}`)
      .then((response) => {
        const updatedSpotStatusArrays = response.data[0].spotStatusArrays.map((spotStatus, index) => {
          return {
            ...spotStatus,
            category: `${spotStatus?.age_category} ${spotStatus.category}`,
          };
        });
        setTournament({ ...response.data[0], spotStatusArrays: updatedSpotStatusArrays });
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [tournamentId]);

  const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const isAM = parseInt(hours) < 12;
    let formattedHours = parseInt(hours) % 12 || 12;
    return `${formattedHours}:${minutes} ${isAM ? 'AM' : 'PM'}`;
  };

  function formatDate(dateString) {
    const parts = dateString.split('-');
    const day = parts[0];
    const month = parseInt(parts[1], 10);
    const yearLastTwoDigits = parts[2];
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const formattedMonth = months[month - 1];
    return `${day} ${formattedMonth} ${yearLastTwoDigits}`;
  }

  const handleTotalEntriesClick = async (id, players, spotcategory) => {
    try {
      setplayers(players);
      setShowModal(true);
      const response = await axios.get(`/getRegisteredParticipants?TOURNAMENT_ID=${id}`);
      setModalData(response.data.PLAYERS);
      setCategory(spotcategory);
    } catch (error) {
      console.error("Error fetching player data:", error);
      setModalData([]);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData([]);
    setFixtureModal(false);
  };

  const handleEditButtonClick = (spotStatus) => {
    const editedData = {
      ID: spotStatus.id,
      START_DATE: spotStatus.start_date,
      END_DATE: spotStatus.end_date,
      START_TIME: spotStatus.start_time,
      END_TIME: spotStatus.end_time,
    };

    setEditModalData(editedData);
  };

  const handleEditPrizeButtonClick = (spotStatus) => {
    const editedData = {
      ID: spotStatus.id,
      ENTRY_FEE: spotStatus.ENTRY_FEE,
      GOLD: spotStatus.GOLD,
      SILVER: spotStatus.SILVER,
      BRONZE: spotStatus.BRONZE,
    };

    setPrizeData(editedData);
  };

  const handleDownload = async (tournamentId, name, category) => {
    try {
      const response = await axios.get(`/downloadStats?TOURNAMENT_ID=${tournamentId}`, {
        responseType: 'blob',
      });

      if (response.status === 200) {
        const contentDisposition = response.headers['content-disposition'];
        let filename = contentDisposition
          ? contentDisposition.split(';')[1].split('filename=')[1].trim()
          : 'details.csv';
        filename = `${name}_${category}_PlayerDetails.csv`;

        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename;

        // Trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      } else {
        console.error('Unexpected status code:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleAddEntriesClick = (id, spotcategory) => {
    // toast.error('This feature is in progress');
    let entryData; // Declare entryData in the outer scope

    if (spotcategory === 'DOUBLES') {
      entryData = {
        ID: id,
        USERID1: "",
        USERID2: "",
      };
    } else {
      entryData = {
        ID: id,
        USERID: [],
      };
    }

    setCategory(spotcategory);
    setEntryData(entryData);
  };


  const handleDoublePlayerInputChange = (e) => {
    const { name, value } = e.target;
    setDoublePlayer((prevDoublePlayer) => ({
      ...prevDoublePlayer,
      [name]: value,
    }));
  };

  const handleAddEntry = () => {
    if (newEntry.trim() !== '') {
      const updatedUserIDs = [...entryData.USERID, newEntry];
      setEntryData({ ...entryData, USERID: updatedUserIDs });
      setNewEntry('');
    }
  };

  const handleEntriesSave = async () => {
    let requestData;
    if (category === 'SINGLES') {
      requestData = {
        ID: entryData.ID,
        USERID: entryData.USERID,
      }
    } else {
      requestData = {
        ID: entryData.ID,
        USERID1: doublePlayer.USERID1,
        USERID2: doublePlayer.USERID2

      }

    }
    console.log(requestData);
    try {
      const response = await axios.post('/addUserToOpenTournament', requestData);
      if (response.status === 200) {
        console.log('Successfully Added Entries:', response.data);
        toast.success("Successfully Added Entries");
      } else {
        console.error('Unexpected status code:', response.status);
        toast.error("An unexpected error occurred while adding entries.");
      }
    } catch (error) {
      if (error.response || error.response.status === 400) {
        toast.error(error.response.data);
      } else {
        console.error('Error:', error);
        toast.error("An unexpected error occurred while adding entries.");
      }
    }
  };

  const handleEditModalSave = () => {
    const requestData = {
      ID: editModalData.ID,
      START_DATE: editModalData.START_DATE,
      END_DATE: editModalData.END_DATE,
      START_TIME: editModalData.START_TIME,
      END_TIME: editModalData.END_TIME,
    };

    axios.post('/editMatchTiming', requestData)
      .then((response) => {
        console.log('Successfully updated tournament data:', response.data);
        toast.success("Successfully updated tournament timings");
      })
      .catch((error) => {
        toast.error("Some error occured while updating tournament data");
        console.error('Error updating tournament data:', error);
      });
  };

  const handleEditPrizeSave = () => {
    const requestData = {
      ID: editPrizeData.ID,
      ENTRY_FEE: parseInt(editPrizeData.ENTRY_FEE),
      GOLD: parseInt(editPrizeData.GOLD),
      SILVER: parseInt(editPrizeData.SILVER),
      BRONZE: parseInt(editPrizeData.BRONZE),
    };

    axios.post('/editMatchPrize', requestData)
      .then((response) => {
        console.log('Successfully updated tournament data:', response.data);
        toast.success("Successfully updated tournament prize");

      })
      .catch((error) => {
        console.error('Error updating tournament data:', error);
      });
  };

  const handleFixtures = (id) => {
    setFixtureID(id);
    // setplayers(n);
    setFixtureModal(true);
  };

  return (
    <div className="categories-container">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="category">
          <h1 className="title">{tournament.TOURNAMENT_NAME}</h1>
          {tournament.spotStatusArrays.map((spotStatus, index) => (
            <div className="category-card" key={index}>
              <div className="circular-image">
                <img src={tournament.IMG_URL} alt="Tournament" />
              </div>
              <div className="tournament-details">
                <h2>{spotStatus.category}</h2>
                <p>ID: {spotStatus.id}</p>
                <p>{formatDate(spotStatus.start_date)} - {formatDate(spotStatus.end_date)}</p>
                <p>{formatTime(spotStatus.start_time)} - {formatTime(spotStatus.end_time)}</p>
                <p>{spotStatus.totalEntries} Entries</p>
                <p>Gold: {spotStatus.GOLD} Silver: {spotStatus.SILVER} Bronze: {spotStatus.BRONZE} </p>
                <p>Entry Fee: {spotStatus.ENTRY_FEE}</p>
                <button className="btn2" onClick={() => handleTotalEntriesClick(spotStatus.id, spotStatus.totalEntries, spotStatus.CATEGORY_TYPE)}>
                  Participants
                </button>
                <button className="btn2" onClick={() => handleAddEntriesClick(spotStatus.id, spotStatus.CATEGORY_TYPE)}>
                  Add Entries
                </button>
                <button className="btn2" onClick={() => handleDownload(spotStatus.id, tournament.TOURNAMENT_NAME, spotStatus.category)}>
                  Download CSV
                </button>
                <button className="btn2" onClick={() => handleFixtures(spotStatus.id)}>
                  Fixtures
                </button>
                <button className="btn2" onClick={() => handleEditButtonClick(spotStatus)}>
                  Edit Timings
                </button>
                <button className="btn2" onClick={() => handleEditPrizeButtonClick(spotStatus)}>
                  Edit Prize
                </button>
                <button className="btn2">
                  <Link to={`/summary/${spotStatus.id}`} className="white-link">
                    Summary Report
                  </Link>
                </button>
                <div className="buttons-container">
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>User Details</h2>
            {category === "SINGLES" ?
              <table className="custom-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>USERID</th>
                    <th>Added by</th>
                  </tr>
                </thead>
                <tbody>
                  {modalData.map((user, index) => (
                    <tr key={index}>
                      <td><div className="profile-pic">
                        <img
                          src={user.PROFILE_PIC}
                          alt="Profile"
                        />
                      </div></td>
                      <td>{user.NAME}</td>
                      <td>{user.USERID}</td>
                      <td>{user.ADDED_BY}</td>
                    </tr>
                  ))}
                </tbody>
              </table> :
              <table className="custom-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>USERID</th>
                    <th>Added by</th>
                    <th></th>
                    <th>Partner Name</th>
                    <th>Partner USERID</th>
                    <th>Partner Added by</th>
                  </tr>
                </thead>
                <tbody>
                  {modalData.map((user, index) => (
                    <tr key={index}>
                      <td><div className="profile-pic">
                        <img
                          src={user.PROFILE_PIC_USER1}
                          alt="Profile"
                        />
                      </div></td>
                      <td>{user.NAME1}</td>
                      <td>{user.USERID1}</td>
                      <td>{user.ADDED_BY1}</td>
                      <td><div className="profile-pic">
                        <img
                          src={user.PROFILE_PIC_USER2}
                          alt="Profile"
                        />
                      </div></td>
                      <td>{user.NAME2}</td>
                      <td>{user.USERID2}</td>
                      <td>{user.ADDED_BY2}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
            {modalData.length === 0 && (
              <p>{players} users found.</p>
            )}
          </div>
        </div>
      )}

      {editModalData && (
        <div className="card-modal">
          <div className="card-modal-content">
            <span className="card-close" onClick={() => setEditModalData(null)}>&times;</span>
            <h2>Edit Tournament Details</h2>
            <p>
              ID: <span>{editModalData.ID}</span>
            </p>
            <p>Start Date: <input type="text" value={editModalData.START_DATE} onChange={(e) => setEditModalData({ ...editModalData, START_DATE: e.target.value })} /></p>
            <p>End Date: <input type="text" value={editModalData.END_DATE} onChange={(e) => setEditModalData({ ...editModalData, END_DATE: e.target.value })} /></p>
            <p>Start Time: <input type="text" value={editModalData.START_TIME} onChange={(e) => setEditModalData({ ...editModalData, START_TIME: e.target.value })} /></p>
            <p>End Time: <input type="text" value={editModalData.END_TIME} onChange={(e) => setEditModalData({ ...editModalData, END_TIME: e.target.value })} /></p>
            <button className="btn" onClick={handleEditModalSave}>Save</button>
          </div>
        </div>
      )}

      {entryData && (
        <div className="card-modal">
          <div className="card-modal-content">
            <span className="card-close" onClick={() => setEntryData(null)}>&times;</span>
            <h2>Add Entries</h2>
            <p>
              ID: <span>{entryData.ID}</span>
            </p>
            {category === 'SINGLES' ? (
              <>
                <p>USERID:</p>
                <input
                  type="text"
                  value={newEntry}
                  onChange={(e) => setNewEntry(e.target.value)}
                />
                <button onClick={handleAddEntry}>Add Entry</button>
                <div className="entered-data">
                  {entryData.USERID.map((entry, index) => (
                    <div key={index} className="entry-container">
                      <span>{entry}</span>
                    </div>
                  ))}
                </div>
              </>) :
              (<>
                <p>USERID 1:</p>
                <input
                  type="text"
                  name="USERID1"
                  value={doublePlayer.USERID1}
                  onChange={handleDoublePlayerInputChange}
                />
                <p>USERID 2:</p>
                <input
                  type="text"
                  name="USERID2"
                  value={doublePlayer.USERID2}
                  onChange={handleDoublePlayerInputChange}
                />
              </>)
            }
            <button className="btn" onClick={handleEntriesSave}>Save</button>
          </div>
        </div>
      )}

      {editPrizeData && (
        <div className="card-modal">
          <div className="card-modal-content">
            <span className="card-close" onClick={() => setPrizeData(null)}>&times;</span>
            <h2>Edit Prize Details</h2>
            <p>
              ID: <span>{editPrizeData.ID}</span>
            </p>
            <p>Entry Fee: <input type="text" value={editPrizeData.ENTRY_FEE} onChange={(e) => setPrizeData({ ...editPrizeData, ENTRY_FEE: e.target.value })} /></p>
            <p>Gold: <input type="text" value={editPrizeData.GOLD} onChange={(e) => setPrizeData({ ...editPrizeData, GOLD: e.target.value })} /></p>
            <p>Silver: <input type="text" value={editPrizeData.SILVER} onChange={(e) => setPrizeData({ ...editPrizeData, SILVER: e.target.value })} /></p>
            <p>Bronze: <input type="text" value={editPrizeData.BRONZE} onChange={(e) => setPrizeData({ ...editPrizeData, BRONZE: e.target.value })} /></p>
            <button className="btn" onClick={handleEditPrizeSave}>Save</button>
          </div>
        </div>
      )}

      {FixtureModal && (
        <div className="card-modal">
          <span className="close" onClick={handleCloseModal}>&times;</span>
          <Fixtures fixtureId={FixtureID} players={8} />
        </div>
      )}


    </div>
  );
};

export default Categories;
