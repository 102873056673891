import axios from "axios";
// const link = localStorage.getItem("url") || "https://ardentsporttest.azurewebsites.net";
const link = localStorage.getItem("url") || "https://live.ardentsport.com";

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcmRlbnRzcG9ydCJ9.XO9cb_qKGhKlo1LgbWJkERhnxVk1rOZC34hWBIW2FKI';
const axiosInstance = axios.create({
  baseURL: link,
  headers: {
    authorization: `Bearer ${token}`,
  },
});

export default axiosInstance;

// http://ec2-43-205-129-118.ap-south-1.compute.amazonaws.com:3000
// https://api.ardentsport.com
// http://localhost:3000