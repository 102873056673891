import React, { useState } from "react";
import "./Login.css";
import { useUrl } from "./UrlContext";
import { FiLock, FiUser } from "react-icons/fi";
import logo from "./images/logo.png";
import toast from "react-hot-toast";
import axios from "./Axios";
const LoginPage = () => {
  const { setIsLoggedIn } = useUrl();
  const passwordVisible = false;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleLogin = async () => {
    try {
      const data = JSON.stringify({ email, password });
      const response = await axios.post(
        `/dashboardLogin`,
        data,
        // 'https://ardentsport.azurewebsites.net/dashboardLogin',
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 201) {
        toast.success("Only Admin at Ardent Sports can access this website");
        // setTimeout(() => {
        //   setIsLoggedIn(true);
        //   window.location.href = "/login"; // You can use Navigate here if you're using a router library
        // }, 2000);
      } else if (response.status === 200) {
        const { token } = response.data;
        localStorage.setItem("token", token);
        setIsLoggedIn(true);
        toast.success("Successfully Logged In");
      } else if (response.status === 400) {
        toast.error("Incorrect Password");
        // setTimeout(() => {
        //   setIsLoggedIn(true);
        //   window.location.href = "/login"; // You can use Navigate here if you're using a router library
        // }, 2000);
      } else if (response.status === 401) {
        toast.error("Only Admin at Ardent Sports can access this website");
        // setTimeout(() => {
        //   setIsLoggedIn(true);
        //   window.location.href = "/login"; // You can use Navigate here if you're using a router library
        // }, 2000);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
    }
  };

  return (
    <div>
      <div className="w-full min-h-screen">
        <div className="login-container">
          <section
            className="home show"
            style={{
              backgroundImage: `url(https://th.bing.com/th/id/OIP.xa1rKg7syVWGq3ZVnSG0HAHaEK?w=342&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7)`,
            }}
          >
            <div className="form_container">
              <i className="uil uil-times form_close"></i>
              <div className="form login_form active">
                <form action="#">
                  <h2
                    style={{
                      marginLeft: "20%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={logo}
                      alt="Ardent Sport"
                      style={{
                        width: "50px",
                        height: "auto",
                        marginRight: "10px",
                      }}
                    />
                    Ardent Sport
                  </h2>
                  <div
                    className="input_box"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FiUser className="email" />
                    <input
                      type="text"
                      placeholder="Enter your email"
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setErrorMessage(null);
                      }}
                    />
                  </div>
                  {errorMessage && !email && (
                    <div
                      style={{
                        color: "red",
                        marginBottom: "10px",
                        marginLeft: "12px",
                        marginTop: "5px",
                      }}
                      className="error-message"
                    >
                      Email cannot be empty!
                    </div>
                  )}
                  <div
                    className="input_box"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FiLock className="password" />
                    <input
                      type={passwordVisible ? "text" : "password"}
                      placeholder="Enter your password"
                      required
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setErrorMessage(null);
                      }}
                    />
                  </div>
                  {errorMessage && !password && (
                    <div
                      style={{
                        color: "red",
                        marginBottom: "10px",
                        marginLeft: "12px",
                        marginTop: "5px",
                      }}
                      className="error-message"
                    >
                      Password cannot be empty!
                    </div>
                  )}
                  <button onClick={handleLogin} className="button">
                    Login Now
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
