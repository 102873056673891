import React, { useState } from 'react';
import './Newtournament.css';
import axios from '../Axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const NewTournament = () => {
  const [tournament, setTournament] = useState({
    ORGANIZER_NAME: '',
    ORGANIZER_ID: '',
    USERID: '',
    CATEGORY: '',
    EVENT_TYPE: '',
    NO_OF_KNOCKOUT_ROUNDS: '',
    ENTRY_FEE: '',
    GOLD: '',
    SILVER: '',
    BRONZE: '',
    OTHER: '',
    PRIZE_POOL: '',
    TOURNAMENT_NAME: '',
    CITY: '',
    LOCATION: '',
    START_DATE: '',
    END_DATE: '',
    START_TIME: '',
    END_TIME: '',
    AGE_CATEGORY: '',
    NO_OF_COURTS: '',
    SPORT: ''
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const categoryOptions = ['Men\'s Singles', 'Women\'s Singles', 'Men\'s Doubles', 'Women\'s Doubles', 'Mixed Doubles', 'Boys Singles', 'Girls Singles', 'Boys Doubles', 'Girls Doubles'];
  const ageCategoryOptions = ['Open', 'U11', 'U12', 'U13', 'U14', 'U15', 'U17', 'U19', 'Above 30', 'Above 35', 'Above 40', 'Above 45', 'Above 50', 'Above 55', 'Above 60'];
  const sportCategoryOptions = ['Badminton', 'Table Tennis', 'Cricket'];


  const handleSave = () => {
      axios.post('/createMultipleTournament', tournament)
      .then((response) => {
        console.log('New Tournament created:', response.data);
      })
      .catch((error) => {
        console.error('Error creating new tournament:', error);
      });
  };


  return (
    <div className="new-tournament-container">
      <h2 className="new-tournament-title">New Tournament Form:</h2>
      <form className="new-tournament-form" onSubmit={handleSave}>
        <div className="new-tournament-field">
          <label>Organizer Name:</label>
          <input
            type="text"
            name="ORGANIZER_NAME"
            className="new-form-input"
            value={tournament.ORGANIZER_NAME}
            placeholder='Organizer Name'
            onChange={(e) => setTournament({ ...tournament, ORGANIZER_NAME: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Organizer ID:</label>
          <input
            type="text"
            name="ORGANIZER_ID"
            className="new-form-input"
            placeholder='Organizer ID'
            value={tournament.ORGANIZER_ID}
            onChange={(e) => setTournament({ ...tournament, ORGANIZER_ID: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>User ID:</label>
          <input
            type="text"
            name="USERID"
            className="new-form-input"
            placeholder='User ID'
            value={tournament.USERID}
            onChange={(e) => setTournament({ ...tournament, USERID: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Tournament Name:</label>
          <input
            type="text"
            name="TOURNAMENT_NAME"
            className="new-form-input"
            placeholder='Enter Tournament Name'
            value={tournament.TOURNAMENT_NAME}
            onChange={(e) => setTournament({ ...tournament, TOURNAMENT_NAME: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Category:</label>
          <select
            value={tournament.CATEGORY}
            onChange={(e) => setTournament({ ...tournament, CATEGORY: e.target.value })}
          >
            <option value="">Select a Category</option>
            {categoryOptions.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="new-tournament-field">
          <label>Age Category:</label>
          <select
            name="AGE_CATEGORY"
            className="new-form-input"
            value={tournament.AGE_CATEGORY}
            onChange={(e) => setTournament({ ...tournament, AGE_CATEGORY: e.target.value })}
          >
            <option value="">Select Age Category</option>
            {ageCategoryOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="new-tournament-field">
          <label>Sport:</label>
          <select
            value={tournament.SPORT}
            onChange={(e) => setTournament({ ...tournament, SPORT: e.target.value })}
          >
            <option value="">Select a Sport</option>
            {sportCategoryOptions.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="new-tournament-field">
          <label>Event Type:</label>
          <input
            type="text"
            name="EVENT_TYPE"
            className="new-form-input"
            value={tournament.EVENT_TYPE}
            onChange={(e) => setTournament({ ...tournament, EVENT_TYPE: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Number of Knockout Rounds:</label>
          <input
            type="text"
            name="NO_OF_KNOCKOUT_ROUNDS"
            className="new-form-input"
            value={tournament.NO_OF_KNOCKOUT_ROUNDS}
            onChange={(e) => setTournament({ ...tournament, NO_OF_KNOCKOUT_ROUNDS: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Entry Fee:</label>
          <input
            type="text"
            name="ENTRY_FEE"
            className="new-form-input"
            value={tournament.ENTRY_FEE}
            placeholder='Enter Amount'
            onChange={(e) => setTournament({ ...tournament, ENTRY_FEE: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Gold:</label>
          <input
            type="text"
            name="GOLD"
            className="new-form-input"
            value={tournament.GOLD}
            placeholder='Enter Amount'
            onChange={(e) => setTournament({ ...tournament, GOLD: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Silver:</label>
          <input
            type="text"
            name="SILVER"
            className="new-form-input"
            value={tournament.SILVER}
            placeholder='Enter Amount'
            onChange={(e) => setTournament({ ...tournament, SILVER: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Bronze:</label>
          <input
            type="text"
            name="BRONZE"
            className="new-form-input"
            value={tournament.BRONZE}
            placeholder='Enter Amount'
            onChange={(e) => setTournament({ ...tournament, BRONZE: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Other:</label>
          <input
            type="text"
            name="OTHER"
            className="new-form-input"
            value={tournament.OTHER}
            onChange={(e) => setTournament({ ...tournament, OTHER: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Prize Pool:</label>
          <input
            type="text"
            name="PRIZE_POOL"
            className="new-form-input"
            placeholder='Enter Amount'
            value={tournament.PRIZE_POOL}
            onChange={(e) => setTournament({ ...tournament, PRIZE_POOL: e.target.value })}
          />
        </div>

        <div className="new-tournament-field">
          <label>City:</label>
          <input
            type="text"
            name="CITY"
            className="new-form-input"
            value={tournament.CITY}
            placeholder='Enter host City'
            onChange={(e) => setTournament({ ...tournament, CITY: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Location:</label>
          <input
            type="text"
            name="LOCATION"
            className="new-form-input"
            placeholder='Enter a location'
            value={tournament.LOCATION}
            onChange={(e) => setTournament({ ...tournament, LOCATION: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>Start Date:</label>
          <DatePicker
            name="START_DATE"
            className="new-form-input"
            selected={startDate}
            onChange={(date) => {
              const formattedDate = format(date, 'dd-MM-yyyy');
              setStartDate(date);
              setTournament({ ...tournament, START_DATE: formattedDate });
            }}
            dateFormat="dd-MM-yyyy"
          />
        </div>
        <div className="new-tournament-field">
          <label>End Date:</label>
          <DatePicker
            name="END_DATE"
            className="new-form-input"
            selected={endDate}
            onChange={(date) => {
              const formattedDate = format(date, 'dd-MM-yyyy');
              setEndDate(date);
              setTournament({ ...tournament, END_DATE: formattedDate });
            }}
            dateFormat="dd-MM-yyyy"
          />
        </div>
        <div className="new-tournament-field">
          <label>Start Time:</label>
          <input
            type="text"
            name="START_TIME"
            className="new-form-input"
            value={tournament.START_TIME}
            placeholder='HH-MM Format'
            onChange={(e) => setTournament({ ...tournament, START_TIME: e.target.value })}
          />
        </div>
        <div className="new-tournament-field">
          <label>End Time:</label>
          <input
            type="text"
            name="END_TIME"
            className="new-form-input"
            value={tournament.END_TIME}
            placeholder='HH-MM Format'
            onChange={(e) => setTournament({ ...tournament, END_TIME: e.target.value })}
          />
        </div>

        <div className="new-tournament-field">
          <label>Number of Courts:</label>
          <input
            type="text"
            name="NO_OF_COURTS"
            className="new-form-input"
            value={tournament.NO_OF_COURTS}
            placeholder='Enter available courts'
            onChange={(e) => setTournament({ ...tournament, NO_OF_COURTS: e.target.value })}
          />
        </div>

        <div className="new-tournament-buttons">
          <button className="new-tournament-save-btn" type="submit">Save</button>
        </div>
      </form>
    </div>
  );
};

export default NewTournament;
