import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Toaster } from "react-hot-toast";
import { UrlProvider } from './UrlContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

// 37430967071-am4ku7hr8g01r9f8o7ngdaivlu8k9rr6.apps.googleusercontent.com
// 254238376454-3787nkuu004852d41f1hm4hb8ap2v9ho.apps.googleusercontent.com
const CLIENT_ID = '254238376454-3787nkuu004852d41f1hm4hb8ap2v9ho.apps.googleusercontent.com';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Toaster
  containerStyle={{
    zIndex: 100000,
  }}
/>
  <UrlProvider>
  <GoogleOAuthProvider clientId={CLIENT_ID}>
      <App />
    </GoogleOAuthProvider>
  </UrlProvider>
  </React.StrictMode>
);

