import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Sidebar from "./Sidebar";
import Categories from "./Components/Categories";
import Tournament from "./Components/Tournament";
import Users from "./Components/Users";
import Dashboard from "./Components/Dashboard";
import Analytics from "./Components/Analytics";
import Notification from "./Components/Notification";
import SentNotification from "./Components/SentNotification";
import SummaryReport from "./Components/SummaryReport";
import MainSummary from "./Components/MainSummary";
import TeamEventDetails from "./Components/TeamEventDetails";
import Login from "./Login";
import { useUrl } from "./UrlContext";
import EventNews from "./Components/EventNews";

function App() {
  const { isLoggedIn, setIsLoggedIn } = useUrl();
  const [selectedSection, setSelectedSection] = useState("Tournaments");

  useEffect(() => {
    async function checkTokenExpiration() {
      const token = localStorage.getItem("token");
      if (token) {
        const url = `https://live.ardentsport.com/check-token-expiration`;
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200) {
            setIsLoggedIn(true);
            <Navigate to="/" />;
          } else if (response.status === 401) {
            setIsLoggedIn(false);
            <Navigate to="/login" />;
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        setIsLoggedIn(false);
        <Navigate to="/login" />;
      }
    }
    checkTokenExpiration();
  }, [setIsLoggedIn]);

  const handleSelectSection = (section) => {
    setSelectedSection(section);
  };

  return (
    <Router>
      {isLoggedIn ? (
        <div className="app-container">
          <div className="sidebar">
            <Sidebar
              onSelectSection={handleSelectSection}
              selectedSection={selectedSection}
            />
          </div>
          <div className="content-container">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/tournament" element={<Tournament />} />
              <Route
                path="/categories/:tournamentId"
                element={<Categories />}
              />
              <Route path="/user" element={<Users />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/notifications" element={<Notification />} />
              <Route path="/Update" element={<SentNotification />} />
              <Route path="/summary/:id" element={<SummaryReport />} />
              <Route path="/main-summary/:id" element={<MainSummary />} />
              <Route path="/more-info/:id" element={<TeamEventDetails />} />
              <Route path="/eventNews" element={<EventNews />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
