import { createContext, useContext, useState, useEffect } from 'react';
import axios from './Axios';

const UrlContext = createContext();

export function UrlProvider({ children }) {
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem('selectedOption') || 'url1'
  );
  const [url, setUrl] = useState(
    localStorage.getItem('url') || 'https://live.ardentsport.com'
  );
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem('isLoggedIn') === 'true'
  );

  const [googleAccessToken, setGoogleAccessToken] = useState('');

  const updateUrl = (newSelectedOption, newUrl) => {
    setSelectedOption(newSelectedOption);
    setUrl(newUrl);
    localStorage.setItem('selectedOption', newSelectedOption);
    localStorage.setItem('url', newUrl);
    axios.defaults.baseURL = newUrl;
  };

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn ? 'true' : 'false');
  }, [isLoggedIn]);

  return (
    <UrlContext.Provider value={{ selectedOption, url, updateUrl, isLoggedIn, setIsLoggedIn, googleAccessToken, setGoogleAccessToken }}>
      {children}
    </UrlContext.Provider>
  );
}

export function useUrl() {
  return useContext(UrlContext);
}
