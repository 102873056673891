import React, { useEffect, useState, useRef } from 'react';
import axios from '../Axios';
import Loadmore from './Pagination';

const Notification = () => {
  const [entry, setEntry] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [allDataFetched, setAllDataFetched] = useState(false);
  const [intialLoading, setIntialLoading] = useState(false);
  // Create a ref to the last table row
  const lastTableRowRef = useRef(null);
  useEffect(() => {
    if (!allDataFetched) {
      if (intialLoading) { loadMoreData(pageNo); }
    }
  }, [pageNo, intialLoading,allDataFetched]);
  const loadMoreData = (page) => {
    // Simulate a delay of 1.5 seconds before fetching data
    setIsLoading(true);
    setTimeout(() => {
      axios.get(`/allEntryNotifications?pageNo=${page}`)
        .then((response) => {
          const newData = response.data;
          console.log(newData.length);
          if (newData.length === 0) {
            setAllDataFetched(true);
          } else {
            setEntry((prevData) => [...prevData, ...newData]);
            setPageNo(page + 1);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setIsLoading(false);
        });
    }, 1000); // Simulated delay
  };
  useEffect(() => {
    setIntialLoading(true);
  }, []);

  // Add a scroll event listener to detect when the user scrolls to the bott// Make sure to include pageNo and allDataFetched as dependencies to handleScroll

  const categoryMappings = {
    MS: "Men's Singles",
    WS: "Women's Singles",
    MD: "Men's Doubles",
    WD: "Women's Doubles",
    MixD: "Mixed Doubles",
    BD: "Boys Doubles",
    BS: "Boys Singles",
    GS: "Girls Singles",
    GD: "Girls Doubles"
  };

  const formatDate = (timestamp) => {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(timestamp).toLocaleDateString('en-US', options);
  };

  const formatTime = (timestamp) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(timestamp).toLocaleTimeString('en-US', options);
  };

  const tableStyle = {
    color: 'black',
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'center',
  };

  const cellStyle = {
    borderBottom: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
  };

  const nowrapCellStyle = {
    ...cellStyle,
    whiteSpace: 'nowrap',
  };

  return (
    <div>
      <div>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={nowrapCellStyle}>Name</th>
              <th style={nowrapCellStyle}>Mobile No.</th>
              <th style={nowrapCellStyle}>Tournament</th>
              <th style={nowrapCellStyle}>Category</th>
              <th style={nowrapCellStyle}>Entry Fee</th>
              <th style={nowrapCellStyle}>Booking By</th>
              <th style={nowrapCellStyle}>Booking Time</th>
            </tr>
          </thead>
          <tbody>
            {entry.map((user, index) => {
              // Check if it's the last row
              const isLastRow = index === entry.length - 1;
              const tournamentParts = user.TOURNAMENT_NAME.split('-');
              const tournamentName = tournamentParts.slice(0, -2).join('-');
              const category = tournamentParts[tournamentParts.length - 2];
              const ageGroup = tournamentParts[tournamentParts.length - 1];
              let userNameParts, userIDParts, userPhoneParts;
              if (user.NAME && user.NAME.includes('-')) {
                userNameParts = user.NAME.split('-');
                userIDParts = user.USERID.split('-');
                if (user.PHONE)
                  userPhoneParts = user.PHONE.split('-');
              }

              return (
                <tr key={user._id} ref={isLastRow ? lastTableRowRef : null} style={user.PHONE === "N/A" ? {color : '#e55855'} : {}}>
                  {user.NAME.includes('-') ? (
                    <td style={nowrapCellStyle}>
                      <span style={{ fontWeight: "bolder" }}>{userNameParts[0]}</span><br />
                      ({userIDParts[0]})<br />
                      <span style={{ fontWeight: "bolder" }}>{userNameParts[1]}</span><br />
                      ({userIDParts[1]})
                    </td>
                  ) : (
                    <td style={nowrapCellStyle}>
                      <span style={{ fontWeight: "bolder" }}>{user.NAME}</span><br />
                      ({user.USERID})
                    </td>
                  )}
                  {user.PHONE && user.PHONE.includes('-') ? (
                    <td style={nowrapCellStyle}>
                      {userPhoneParts[0]}<br />
                      {userPhoneParts[1]}
                    </td>
                  ) : (
                    <td style={nowrapCellStyle}>{user.PHONE}</td>
                  )}
                  <td style={cellStyle}>{tournamentName}</td>
                  <td style={nowrapCellStyle}>
                    {ageGroup} {categoryMappings[category]}<br />
                    ({user.TOURNAMENT_ID})
                  </td>
                  <td style={nowrapCellStyle}>
                    {user.STATUS ? user.ENTRY_FEE : "-"}
                  </td>
                  <td style={nowrapCellStyle}>
                    <span style={{ fontWeight: "bolder" }}>{user?.BOOKING_NAME}</span><br />
                    ({user.BOOKING_ID})
                  </td>
                  <td style={nowrapCellStyle}>
                    {formatDate(user.CREATE_TIMESTAMP)}<br />
                    {formatTime(user.CREATE_TIMESTAMP)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {isLoading && (
          <Loadmore />
        )}
        {allDataFetched && (
          <div className="all-data-fetched-message">
            All data has been loaded.
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
