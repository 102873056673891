import React, { useState } from 'react';
import './Tournamentcard.css';
import axios from '../Axios';
import { Link } from 'react-router-dom';
import { toast } from "react-hot-toast";
import badmintonImg from "../images/1.png";
import tableTennisImg from "../images/2.png";
import tennisImg from "../images/3.png";
import squashImg from "../images/4.png";


const TeamEventCard = ({ tournament }) => {
    const {
        EVENT_ID,
        EVENT_NAME,
        EVENT_ORGANIZER,
        CITY,
        ADDRESS,
        START_DATE,
        END_DATE,
        START_TIME,
        END_TIME,
        ORGANIZER_NAME,
        filePath,
        EVENT_TYPE,
        SPORT,
    } = tournament;
    const [showEditModal, setShowEditModal] = useState(false);
    const [editedData, setEditedData] = useState({
        EVENT_NAME: EVENT_NAME,
        ADDRESS: ADDRESS,
        CITY: CITY,
        START_DATE: START_DATE,
        END_DATE: END_DATE,
        START_TIME: START_TIME,
        END_TIME: END_TIME,
    });

    const [isEditingOrganizer, setIsEditingOrganizer] = useState(false);

    const handleEditButtonClick = () => {
        setShowEditModal(true);
    };

    const handleEditModalSave = () => {
        setShowEditModal(false);
        axios
            .put(`/editTeamEvent/${EVENT_ID}`, editedData)
            .then((response) => {
                toast.success("Successfully updated tournament data");
                console.log('Successfully updated tournament data');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((error) => {
                toast.error("Some error occured while updating tournament data");
                console.error('Error updating tournament data:', error);
            });
    };

    const handleEditOrganizerClick = () => {
        setIsEditingOrganizer(true);
    };

    const handleEditOrganizerSave = async () => {
        try {
            const response = await axios.post(`/editorganizer`, {
                TOURNAMENT_ID: EVENT_ID,
                Oraganizer_name: editedData.ORGANIZER_NAME,
                Oraganizer_id: editedData.ORGANIZER_ID,
            });


            if (response.status === 200) {
                toast.success('Organizer information updated successfully');
                console.log('Organizer information updated successfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                toast.error('Some error occurred while updating organizer information');
                console.error('Error updating organizer information:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating organizer information:', error);
        }
    };

    const handleDelete = async () => {
        try {
            const shouldDelete = window.confirm('Are you sure you want to delete this tournament?');
            if (shouldDelete) {
                const response = await axios.delete(`/removetournament/${EVENT_ID}`);
                if (response.status === 200) {
                    toast.success("Successfully deleted tournament data");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    toast.error("Some error in deleting tournament");
                    console.error('Error deleting tournament:', response.statusText);
                }
            }
        } catch (error) {
            console.error('Error deleting tournament:', error);
        }
    };

    const handleDeleteFile = async () => {
        try {
            const shouldDelete = window.confirm('Are you sure you want to delete this file?');
            if (shouldDelete) {
                const response = await axios.get(`/deletefile/${EVENT_ID}`);
                if (response.status === 200) {
                    toast.success("Successfully deleted File");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    toast.error("Some error in deleting file");
                    console.error('Error deleting file:', response.statusText);
                }
            }
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };

    const formatTime = (time) => {
        const [hours, minutes] = time.split(':');
        const isAM = parseInt(hours) < 12;
        let formattedHours = parseInt(hours) % 12 || 12;
        return `${formattedHours}:${minutes} ${isAM ? 'AM' : 'PM'}`;
    };

    const cardStyle = {
        backgroundColor: SPORT === 'Badminton' ? '#6bb8ff' : '#03c289',
    };


    const formattedStartTime = formatTime(tournament.START_TIME);
    const formattedEndTime = formatTime(tournament.END_TIME);

    function formatDate(dateString) {
        const parts = dateString.split('-');
        const day = parts[0];
        const month = parseInt(parts[1], 10);
        const yearLastTwoDigits = parts[2];
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const formattedMonth = months[month - 1];
        return `${day} ${formattedMonth} ${yearLastTwoDigits}`;
    }

    const handleUpload = async (event) => {
        try {
            const file = event.target.files[0];
            if (!file) {
                throw new Error("Please select a file.");
            }

            const formData = new FormData();
            formData.append("file", file);

            const response = await axios.post(`/uploadfile/${EVENT_ID}`, formData);

            if (response.status === 200) {
                toast.success("File uploaded successfully.");
                window.location.reload();
            }
        } catch (error) {
            toast.error("Error uploading file:", error);
            console.error("Error uploading file:", error);
        }
    };

    const handleDownload = async () => {
        try {
            const response = await axios.get(`/downloadfile/${EVENT_ID}`);
            const { filePath } = response.data;
            if (!filePath) {
                toast.error("File not found for download.");
            } else {
                toast.success("Download started");
                window.location.href = filePath;
            }
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    const sportImageMap = {
        "Badminton": badmintonImg,
        "Table Tennis": tableTennisImg,
        "Lawn Tennis": tennisImg,
        "Squash": squashImg
      };

    return (
        <div className="tournament-card" style={cardStyle}>
            <div className="circular-image">
                <img src={sportImageMap[SPORT]} alt="Tournament" />
            </div>
            <div style={{ color: 'black' }} className="tournament-details">
                <h2>{EVENT_NAME}, <em style={{ fontSize: '1rem', fontFamily: 'Courier New' }}>({EVENT_TYPE})</em></h2>
                <p>Event ID: {EVENT_ID}</p>
                <p>
                    Organizer:{" "}
                    {isEditingOrganizer ? (
                        <>
                            <input
                                type="text"
                                value={editedData.ORGANIZER_NAME}
                                onChange={(e) =>
                                    setEditedData({ ...editedData, ORGANIZER_NAME: e.target.value })
                                }
                            />
                            <input
                                type="text"
                                value={editedData.EVENT_ORGANIZER}
                                onChange={(e) =>
                                    setEditedData({ ...editedData, EVENT_ORGANIZER: e.target.value })
                                }
                            />
                            <button
                                className="btn"
                                onClick={handleEditOrganizerSave}
                            >
                                Save
                            </button>
                        </>
                    ) : (
                        <>
                            {editedData.ORGANIZER_NAME || ORGANIZER_NAME},{" "}
                            {editedData.ORGANIZER_ID || EVENT_ORGANIZER}
                        </>
                    )}
                    <span className="edit-icon btn" onClick={handleEditOrganizerClick} style={{ fontSize: "24px", transform: "rotate(90deg)", margin: "0px 0px 0px 10px", padding: "0px" }}>
                        &#9998;
                    </span>

                </p>
                <p>{ADDRESS}, {CITY}</p>
                <p>{formatDate(START_DATE)} - {formatDate(END_DATE)}</p>
                <p>{formattedStartTime} - {formattedEndTime}</p>
                <div className="buttons-container">
                    <button className="edit-button" onClick={handleEditButtonClick}>
                        Edit
                    </button>
                    <button className="delete-button" onClick={handleDelete}>
                        Delete
                    </button>
                    <label className="custom-file-upload">
                        <input type="file" onChange={handleUpload} />
                    </label>
                    {filePath ? (
                        <button className="download-button" onClick={() => handleDownload()}>
                            1 File
                        </button>
                    ) : (
                        <button className="download-button" disabled>
                            No File
                        </button>
                    )}
                    <button className="delete-button" onClick={handleDeleteFile}>
                        Delete File
                    </button>
                    <button className="categories-button" >
                        <Link to={`/more-info/${EVENT_ID}`} className="white-link">
                            More Info
                        </Link>
                    </button>
                </div>
            </div>

            {showEditModal && (
                <div className="card-modal scroll">
                    <div className="card-modal-content">
                        <span className="card-close" onClick={() => setShowEditModal(false)}>
                            &times;
                        </span>
                        <h2>Edit Team Event</h2>
                        <p>
                            Tournament Name:
                            <input
                                type="text"
                                value={editedData.EVENT_NAME}
                                onChange={(e) =>
                                    setEditedData({ ...editedData, EVENT_NAME: e.target.value })
                                }
                            />
                        </p>
                        <p>
                            Location:
                            <input
                                type="text"
                                value={editedData.ADDRESS}
                                onChange={(e) => setEditedData({ ...editedData, ADDRESS: e.target.value })}
                            />
                        </p>
                        <p>
                            City:
                            <input
                                type="text"
                                value={editedData.CITY}
                                onChange={(e) => setEditedData({ ...editedData, CITY: e.target.value })}
                            />
                        </p>
                        <p>
                            Start Date:
                            <input
                                type="text"
                                value={editedData.START_DATE}
                                onChange={(e) => setEditedData({ ...editedData, START_DATE: e.target.value })}
                            />
                        </p>
                        <p>
                            End Date:
                            <input
                                type="text"
                                value={editedData.END_DATE}
                                onChange={(e) => setEditedData({ ...editedData, END_DATE: e.target.value })}
                            />
                        </p>
                        <p>
                            Start Time:
                            <input
                                type="text"
                                value={editedData.START_TIME}
                                onChange={(e) => setEditedData({ ...editedData, START_TIME: e.target.value })}
                            />
                        </p>
                        <p>
                            End Time:
                            <input
                                type="text"
                                value={editedData.END_TIME}
                                onChange={(e) => setEditedData({ ...editedData, END_TIME: e.target.value })}
                            />
                        </p>
                        <button className="btn" onClick={handleEditModalSave}>
                            Save
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TeamEventCard;
