import React, { useState, useEffect } from 'react';
import TournamentCard from './Tournamentcard';
import TeamEventCard from './TeamEventCard';
import axios from '../Axios';
import NewTournament from './Newtournament';

const Tab = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div>
      {tabs.map((tab) => (
        <button
          key={tab}
          onClick={() => onTabChange(tab)}
          style={{
            backgroundColor: activeTab === tab ? 'black' : 'white',
            color: activeTab === tab ? '#e55855' : 'black',
            marginLeft: '10px',
            marginBottom: '10px',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            fontSize: '16px',
            fontWeight: 'bold',
            cursor: 'pointer',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s ease',
          }}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

const Tournament = () => {
  const [tournamentData, setTournamentData] = useState([]);
  const [PtournamentData, setPTournamentData] = useState([]);
  const [PasttournamentData, setPastTournamentData] = useState([]);
  const [teamEvent, setTeamEvent] = useState([]);
  const [PastteamEvent, setPastTeamEvent] = useState([]);
  const [activeTab, setActiveTab] = useState('Tournaments');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchOption, setSearchOption] = useState('TOURNAMENT_NAME');
  const [isFocused, setIsFocused] = useState(false);
  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    axios.get('/allTournaments')
      .then((response) => {
        if (Array.isArray(response.data)){
          const knockoutEvents = response.data.filter((tournament) => tournament.EVENT_TYPE === 'knockout');
          const teamEvents = response.data.filter((tournament) => tournament.EVENT_TYPE === 'teamEvent');
  
          setPTournamentData(knockoutEvents);
          setTeamEvent(teamEvents);
        }
        
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  

  useEffect(() => {
    axios.get('/pastallTournaments')
      .then((response) => {
        const sortedData = response.data.sort((a, b) => {
          return new Date(b.START_TIMESTAMP) - new Date(a.START_TIMESTAMP);
        });
        const knockoutEvents = sortedData.filter((tournament) => tournament.EVENT_TYPE === 'knockout');
        const teamEvents = sortedData.filter((tournament) => tournament.EVENT_TYPE === 'teamEvent');
        setPastTournamentData(knockoutEvents);
        setPastTeamEvent(teamEvents);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleSearch = () => {
    const filteredTournaments = PtournamentData.filter((tournament) => {
      const searchTerm = searchQuery?.toLowerCase();
      if (searchOption === 'TOURNAMENT_ID') {
        if(tournament.TOURNAMENT_ID)
        return (tournament.TOURNAMENT_ID).includes(searchQuery);
        if(tournament.EVENT_ID)
        return (tournament.EVENT_ID).toString().includes(searchQuery);
      } else if (searchOption === 'TOURNAMENT_NAME') {
        return (tournament.TOURNAMENT_NAME || tournament.EVENT_NAME)?.toLowerCase().includes(searchTerm);
      }
      return false;
    });
    setTournamentData(filteredTournaments);
  };

  return (
    <div>

      <div>
        <Tab
          tabs={['Search Results','Tournaments','Team Events','Past Tournaments','Past Team Events','Create New']}
          activeTab={activeTab}
          onTabChange={(tab) => setActiveTab(tab)}
        />
        {activeTab === 'Create New' && <NewTournament />}

      </div>

      {activeTab === 'Search Results' && (
        <div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleSearch}
              onFocus={handleInputFocus} 
              onBlur={handleInputBlur} 
            placeholder={`Search by ${searchOption === 'TOURNAMENT_ID' ? 'Tournament ID' : 'Tournament Name'}`}
            style={{
              margin: '10px',
              padding: '8px',
              borderRadius: '5px',
              border: `1px solid ${isFocused ? '#ff0000' : '#ccc'}`, 
              outline: 'none',
            }}
          />
          <select
            value={searchOption}
            onChange={(e) => setSearchOption(e.target.value)}
            style={{ margin: '10px', padding: '8px', borderRadius: '5px', border: '1px solid #ccc', outline: 'none' }}
          >
            <option value="TOURNAMENT_ID">Tournament ID</option>
            <option value="TOURNAMENT_NAME">Tournament Name</option>
          </select>
          <button onClick={handleSearch} style={{ padding: '8px', borderRadius: '5px', cursor: 'pointer' }}>
            Search
          </button>
          {tournamentData.map((tournament) => (
            <TournamentCard key={tournament._id} tournament={tournament} />
          ))}
        </div>

      )}
      {activeTab === 'Tournaments' &&
        (Array.isArray(PtournamentData) && PtournamentData.length > 0 ? (
          PtournamentData.map((tournament) => (
            <TournamentCard key={tournament._id} tournament={tournament} />
          ))
        ) : (
          <h1 style={{ marginLeft: '15px' }}>No Tournaments to Show</h1>
        ))}

        {activeTab === 'Team Events' &&
        (Array.isArray(teamEvent) && teamEvent.length > 0 ? (
          teamEvent.map((tournament) => (
            <TeamEventCard key={tournament._id} tournament={tournament} />
          ))
        ) : (
          <h1 style={{ marginLeft: '15px' }}>No Team Events to Show</h1>
        ))}

        {activeTab === 'Past Tournaments' &&
        (Array.isArray(PtournamentData) && PtournamentData.length > 0 ? (
          PasttournamentData.map((tournament) => (
            <TournamentCard key={tournament._id} tournament={tournament} />
          ))
        ) : (
          <h1 style={{ marginLeft: '15px' }}>No Tournaments</h1>
        ))}

        {activeTab === 'Past Team Events' &&
        (Array.isArray(PastteamEvent) && PastteamEvent.length > 0 ? (
          PastteamEvent.map((tournament) => (
            <TeamEventCard key={tournament._id} tournament={tournament} />
          ))
        ) : (
          <h1 style={{ marginLeft: '15px' }}>No Tournaments</h1>
        ))}

    </div>
  );
};

export default Tournament;
