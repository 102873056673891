import React, { useEffect, useState } from 'react';
import axios from '../Axios';
import toast from 'react-hot-toast';
import imageCompression from 'browser-image-compression';
import './SentNotification.css';

const NewComponent = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationBody, setNotificationBody] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [title1, setTitle1] = useState('');
  const [title2, setTitle2] = useState('');
  const [msg1, setMsg1] = useState('');
  const [msg2, setMsg2] = useState('');
  const [type1, setType1] = useState('');
  const [type2, setType2] = useState('');

  // Functions to handle editing and saving title1, title2, msg1, and msg2
  const [editedTitle1, setEditedTitle1] = useState('');
  const [editedTitle2, setEditedTitle2] = useState('');
  const [editedMsg1, setEditedMsg1] = useState('');
  const [editedMsg2, setEditedMsg2] = useState('');

  // State variables to track whether editing is allowed
  const [isEditingTitle1, setIsEditingTitle1] = useState(false);
  const [isEditingTitle2, setIsEditingTitle2] = useState(false);
  const [isEditingMsg1, setIsEditingMsg1] = useState(false);
  const [isEditingMsg2, setIsEditingMsg2] = useState(false);

  // ... other functions ...

  const handleEditTitle1 = () => {
    setIsEditingTitle1(true);
  };

  const handleSaveTitle1 = () => {
    setIsEditingTitle1(false);
    setTitle1(editedTitle1);
  };

  const handleEditTitle2 = () => {
    setIsEditingTitle2(true);
  };

  const handleSaveTitle2 = () => {
    setIsEditingTitle2(false);
    setTitle2(editedTitle2);
  };

  const handleEditMsg1 = () => {
    setIsEditingMsg1(true);
  };

  const handleSaveMsg1 = () => {
    setIsEditingMsg1(false);
    setMsg1(editedMsg1);
  };

  const handleEditMsg2 = () => {
    setIsEditingMsg2(true);
  };

  const handleSaveMsg2 = () => {
    setIsEditingMsg2(false);
    setMsg2(editedMsg2);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/GetNotify'); // Replace with your API endpoint
        if(response.data[0].Purpose==='tournament_created'){
         setTitle1(response.data[0].Title);
         setMsg1(response.data[0].Body)
         setType1(response.data[0].Purpose);
        }
        if(response.data[1].Purpose==='release_fixture'){
          setTitle2(response.data[1].Title);
          setMsg2(response.data[1].Body);
          setType2(response.data[1].Purpose);
         }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error, e.g., display an error message
      }
    };
    fetchData();
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setInputValue(''); // Clear input when changing the selected option
  };

  const handleReset = () => {
    setSelectedOption('');
    setInputValue('');
    setNotificationTitle('');
    setNotificationBody('');
    setSelectedUsers([]); // Clear selected users array
    setSelectedImage(null); // Clear selected image
  };

  const handleImageUpload = async (e) => {
    const imageFile = e.target.files[0];
    
    if (!imageFile) {
      return;
    }

    // Configure compression options
    const options = {
      maxSizeMB: 1,  // Maximum size in MB after compression
      maxWidthOrHeight: 1024, // Maximum width or height after compression
      useWebWorker: true, // Use Web Workers for faster compression (recommended)
    };

    try {
      const compressedImage = await imageCompression(imageFile, options);
      setSelectedImage(compressedImage); // Set the compressed image
    } catch (error) {
      console.error('Error compressing image:', error);
      // Handle error if compression fails
    }
  };

  const handleUpdate1 = () => {
    // Define the data you want to send in the request body
    const data = {
      title: title1,
      message: msg1,
      type: type1,
    };
    // Send a POST request to your backend
    axios
      .post('/PurposeUpdate', data)
      .then((response) => {
        if (response.status === 200) {
          // Handle success, you can add toast or other notifications here
          toast.success('Update successful');
        } else {
          // Handle other status codes if needed
          toast.error('Update failed');
        }
      })
      .catch((error) => {
        // Handle errors from the request
        console.error('Error updating:', error);
        // You can also add a toast or other notification for the error
        toast.error('An error occurred while updating.');
      });
  };
  

  
  const handleUpdate2 = () => {
    // Define the data you want to send in the request body
    const data = {
      title: title2,
      message: msg2,
      type: type2,
    };
  
    // Send a POST request to your backend
    axios
      .post('/PurposeUpdate', data)
      .then((response) => {
        if (response.status === 200) {
          // Handle success, you can add toast or other notifications here
          toast.success('Update successful');
        } else {
          // Handle other status codes if needed
          toast.error('Update failed');
        }
      })
      .catch((error) => {
        // Handle errors from the request
        console.error('Error updating:', error);
        // You can also add a toast or other notification for the error
        toast.error('An error occurred while updating.');
      });
  };
  
  
  
  const handleSubmit = () => {
    // Check if all required fields are not empty
    if (selectedOption && notificationTitle && notificationBody) {
      // All fields are not empty, make the POST request
      const data = new FormData();

      // Set the 'Content-Type' header to 'multipart/form-data'
      data.append('title', notificationTitle);
      data.append('body', notificationBody);

      if (selectedOption === 'users') {
        data.append('users', JSON.stringify(selectedUsers));
      } else if (selectedOption === 'All') {
        data.append('All', '1');
      } else {
        data.append(selectedOption, inputValue);
      }

      // Add the selected image as a file in the request data
      if (selectedImage) {
        data.append('image', selectedImage);
      }
      
      // Determine the type based on the selected option
      const type = selectedOption === 'Tournament_id' ? type1 : type2;
      data.append('type', type);

      axios
        .post('/SendNotification', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            // Handle success with toast message
            toast.success('Notification sent successfully!');
          } else {
            // Handle error with toast message
            toast.error('Failed to send the notification.');
          }
        })
        .catch((error) => {
          // Handle error with toast message
          toast.error('An error occurred while sending the notification: ' + error.message);
        });
    } else {
      // Not all required fields are filled in, show an error toast message
      toast.error('Please fill in all required fields.');
    }
  };

  const handleAddUser = () => {
    if (inputValue) {
      setSelectedUsers([...selectedUsers, inputValue]);
      setInputValue(''); // Clear input after adding user
    }
  };

  const handleRemoveUser = (user) => {
    const updatedUsers = selectedUsers.filter((u) => u !== user);
    setSelectedUsers(updatedUsers);
  };

  return (
    <div className="new-component-container">
      {/* Select an option */}
      <h2>Select an option:</h2>
      <select value={selectedOption} onChange={handleOptionChange}>
        <option value="">Select an option</option>
        <option value="users">Users</option>
        <option value="Tournament_id">Tournament ID</option>
        <option value="Category_id">Category ID</option>
        <option value="All">All</option> {/* Added an "All" option */}
      </select>

      {/* Input fields based on selected option */}
      {selectedOption && (
        <div className="input-container">
          {selectedOption === 'users' && (
            <div>
              <label>Add Users:</label>
              <input
                type="text"
                name="users"
                placeholder="Enter user ID"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button
                style={{
                  padding: '10px 20px',
                  marginTop: '10px',
                  backgroundColor: '#000',
                  color: '#ff6347',
                  border: 'none',
                  borderRadius: '10%',
                }}
                onClick={handleAddUser}
              >
                Add
              </button>
            </div>
          )}
          {(selectedOption === 'Tournament_id' || selectedOption === 'Category_id') && (
            <input
              name={selectedOption}
              type="text"
              placeholder={`Enter ${selectedOption}`}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          )}
        </div>
      )}

      {/* Display selected users */}
      {selectedUsers.length > 0 && (
        <div className="selected-people-container">
          <h3>Selected Users:</h3>
          <ul>
            {selectedUsers.map((user, index) => (
              <li key={index}>
                {user}
                <button onClick={() => handleRemoveUser(user)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Title and message input fields */}
      <div className="input-container">
        <label>Title:</label>
        <input
          type="text"
          name="title"
          placeholder="Enter notification title"
          value={notificationTitle}
          onChange={(e) => setNotificationTitle(e.target.value)}
        />
      </div>

      <div className="input-container">
        <label>Message:</label>
        <textarea
          name="body"
          placeholder="Enter notification body"
          value={notificationBody}
          onChange={(e) => setNotificationBody(e.target.value)}
        ></textarea>
      </div>

      {/* Image upload */}
      <div className="input-container">
        <label>Upload Image:</label>
        <input
          type="file"
          name="image"
          accept="image/*" // Allow only image files
          onChange={handleImageUpload}
        />
      </div>
      {/* Submit and Reset buttons */}
      {selectedOption && (
        <div className="button-container">
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={handleReset}>Reset</button> {/* Reset button */}
        </div>
     ) }
      <div>
        <h3>For Tournament Created:</h3>
        <div className="input-container">
          <label>Title:</label>
          {isEditingTitle1 ? (
            <>
              <input
                type="text"
                name="title"
                placeholder="Enter notification title"
                value={editedTitle1}
                onChange={(e) => setEditedTitle1(e.target.value)}
              />
              <button className="editsave" onClick={handleSaveTitle1}>Save</button>
            </>
          ) : (
            <>
              <input
                type="text"
                name="title"
                placeholder="Enter notification title"
                value={title1}
                readOnly
              />
              <button className="editsave" onClick={handleEditTitle1}>Edit</button>
            </>
          )}
        </div>
        <div className="input-container">
          <label>Message:</label>
          {isEditingMsg1 ? (
            <>
              <input
                type="text"
                name="title"
                placeholder="Enter notification title"
                value={editedMsg1}
                onChange={(e) => setEditedMsg1(e.target.value)}
              />
              <button className="editsave" onClick={handleSaveMsg1}>Save</button>
            </>
          ) : (
            <>
              <input
                type="text"
                name="title"
                placeholder="Enter notification title"
                value={msg1}
                readOnly
              />
              <button className="editsave" onClick={handleEditMsg1}>Edit</button>
            </>
         ) }
         <div className="button-container">
        <button onClick={handleUpdate1}>Confirm</button>
      </div>
        </div>
      </div>

      <div>
        <h3>For Fixture Release:</h3>
        <div className="input-container">
          <label>Title:</label>
          {isEditingTitle2 ? (
            <>
              <input
                type="text"
                name="title"
                placeholder="Enter notification title"
                value={editedTitle2}
                onChange={(e) => setEditedTitle2(e.target.value)}
              />
              <button className="editsave" onClick={handleSaveTitle2}>Save</button>
            </>
          ) : (
            <>
              <input
                type="text"
                name="title"
                placeholder="Enter notification title"
                value={title2}
                readOnly
              />
              <button className="editsave" onClick={handleEditTitle2}>Edit</button>
            </>
         ) }
        </div>
        <div className="input-container">
          <label>Message:</label>
          {isEditingMsg2 ? (
            <>
              <input
                type="text"
                name="title"
                placeholder="Enter notification title"
                value={editedMsg2}
                onChange={(e) => setEditedMsg2(e.target.value)}
              />
              <button className="editsave" onClick={handleSaveMsg2}>Save</button>
            </>
          ) : (
            <>
              <input
                type="text"
                name="title"
                placeholder="Enter notification title"
                value={msg2}
                readOnly
              />
              <button className="editsave" onClick={handleEditMsg2}>Edit</button>
            </>
          )}
       <div className="button-container"> 
        <button onClick={handleUpdate2}>Confirm</button>
      </div>

        </div>
      </div>

    
    
    </div>
  );
};

export default NewComponent;
